import {
    useGetDashboardsQuery,
    useLazyGetLogOnTokenQuery
} from 'APIServices/dundas/Dundas.api';
import { PermissionDashboard } from 'APIServices/dundas/Dundas.types';
import { AppController } from 'config/AppConfig/AppConfig';
import {
    DundasControllerType,
    DundasViewOptions,
    EmbedOptions,
    IConfig
} from 'dundas/dundasTypes';
import { useAppSelector } from 'store';
import { PERMISSIONS_KEYS } from 'utils/constants/permissionsKeys';

export const DASHBOARDS = {
    [PERMISSIONS_KEYS.invoices]: 'invoice',
    [PERMISSIONS_KEYS.storageBilling]: 'storage-billing',
    [PERMISSIONS_KEYS.clientSpend]: 'client-spend',
    [PERMISSIONS_KEYS.dutiesTaxesAnalysis]: 'duties-taxes-analysis',
    [PERMISSIONS_KEYS.analyticsOrderHistory]: 'order-history',
    [PERMISSIONS_KEYS.cycleCountPerformance]: 'cycle-count-performance',
    [PERMISSIONS_KEYS.globalCapabilities]: 'global-capabilities',
    [PERMISSIONS_KEYS.partMaster]: 'part-master',
    [PERMISSIONS_KEYS.substituteParts]: 'substitute-parts',
    [PERMISSIONS_KEYS.missingPart]: 'missing-parts',
    [PERMISSIONS_KEYS.clientBilling]: 'client-billing',
    [PERMISSIONS_KEYS.inventoryTransactionHistory]:
        'inventory-transaction-history',
    [PERMISSIONS_KEYS.outstandingReturns]: 'outstanding-returns',
    [PERMISSIONS_KEYS.outstandingInbounds]: 'outstanding-inbounds',
    [PERMISSIONS_KEYS.inventoryOnHand]: 'inventory-on-hand',
    [PERMISSIONS_KEYS.materialInReviewAnalysis]: 'materials-in-review-analysis',
    [PERMISSIONS_KEYS.dockToStock]: 'dock-to-stock',
    [PERMISSIONS_KEYS.outboundDriveFillRate]: 'outbound-drive-fill-rate',
    [PERMISSIONS_KEYS.outboundDrivePerformance]: 'outbound-drive-performance',
    [PERMISSIONS_KEYS.tenderToCarrierPerformance]:
        'tender-to-carrier-performance'
} as const;

type DashboardsTypes = {
    [key in (typeof DASHBOARDS)[keyof typeof DASHBOARDS]]: string;
};

export const useDundas = (permission: string) => {
    const appConfig = AppController.getInstance().config;
    const isDundasLoading = useAppSelector((state) => state.dundas.isLoading);
    const isDundasError = useAppSelector((state) => state.dundas.isError);
    const dundasBIUrl = appConfig?.dundasBIUrl;
    const [getLogOnToken] = useLazyGetLogOnTokenQuery();
    const { data: dashboardsResponse, isLoading: isDashboardLoading } =
        useGetDashboardsQuery();

    const getDashboardByPage = (): DashboardsTypes | null => {
        const dashboardsData = dashboardsResponse?.data;
        if (!dashboardsData) {
            return null;
        }

        const permissionData: PermissionDashboard | undefined =
            dashboardsData.permissionDashboards.find((item) => {
                return item.permissionKeys.includes(permission);
            });

        return permissionData && permissionData.dashboards
            ? permissionData.dashboards.reduce((acc, item) => {
                  return {
                      ...acc,
                      [item.keys[0]]: item.id
                  };
              }, {} as DashboardsTypes)
            : null;
    };

    const load = async ({
        controllerType = DundasControllerType.DASHBOARD,
        viewOptionsType = DundasViewOptions.VIEW_ONLY,
        targetBlock,
        fileSystemId
    }: IConfig) => {
        if (!dundasBIUrl || !fileSystemId) return;

        const logOnTokenResponse = await getLogOnToken();
        const logOnTokenData = logOnTokenResponse?.data;

        const embedOptions: EmbedOptions = {
            dundasBIUrl: dundasBIUrl,
            controllerType: window.dundas?.embed.ControllerType[controllerType],
            fileSystemId: fileSystemId,
            viewOptions: window.dundas?.embed.ControllerType[viewOptionsType],
            logonTokenId: logOnTokenData?.data.logOnToken ?? '',
            parameterValues: []
        };

        const create = window.dundas?.embed.create(
            targetBlock?.current,
            embedOptions
        );

        const dundasApp = targetBlock ? create : null;

        return dundasApp?.load();
    };

    return {
        load,
        isDundasReady: !isDundasLoading && !isDashboardLoading,
        isDundasError,
        getDashboardByPage
    };
};
