import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithIntercept } from 'APIServices/fetchBaseQuery';
import { REQUESTS_PATH } from 'APIServices/outboundOrderByPart/OutboundOrderByPartApi.types';
import { getServicesWithIds } from 'utils/helpers/activeOrder';
import {
    getSerialsQuery,
    getTransformedDataWithHazard,
    getTransformedSerialNumbersData
} from 'utils/helpers/apiFormatter';
import { getBasePaginationBody } from 'utils/helpers/getBasePaginationBody';
import {
    GetBasicDtoPagination,
    PreCheckDTO,
    PreCheckResponse,
    SubmitFullOrderResponse,
    SubmitOrderDTO,
    SubmitReducedOrderResponse
} from 'utils/types/api/api.types';
import {
    AvailableServicesDTO,
    AvailableServicesResponse
} from 'utils/types/deliveryServices/deliveryServices.types';
import {
    OrderReferenceLabelsDTO,
    OrderReferenceLabelsResponse
} from 'utils/types/general/stepper.types';
import {
    IGetPartTransformedResponse,
    GetSerialsTransformedResponse
} from 'utils/types/parts/parts.types';

export const outboundOrderByPartApi = createApi({
    reducerPath: 'outboundOrderByPartApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getServices: builder.mutation<
            AvailableServicesResponse,
            AvailableServicesDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.GET_SERVICES,
                method: 'POST',
                body: dto,
                headers: {
                    'Api-Version': '3.0'
                }
            }),
            transformResponse(response: AvailableServicesResponse) {
                return getServicesWithIds(
                    response
                ) as AvailableServicesResponse;
            }
        }),
        getOrderReferenceNumberLabels: builder.mutation<
            OrderReferenceLabelsResponse,
            OrderReferenceLabelsDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.ORDER_REFERENCE_LABELS,
                method: 'POST',
                body: dto
            })
        }),
        getOutboundOrderParts: builder.mutation<
            IGetPartTransformedResponse,
            GetBasicDtoPagination
        >({
            query: (dto) => {
                return {
                    url: REQUESTS_PATH.PARTS_OUTBOUND,
                    method: 'POST',
                    body: getBasePaginationBody({
                        ...dto,
                        filterFields: 'CustomDescription',
                        fixedSorter: 'partNumber'
                    })
                };
            },
            extraOptions: {
                flowStart: true
            },
            transformResponse: getTransformedDataWithHazard
        }),
        getSerialNumbers: builder.mutation<
            GetSerialsTransformedResponse,
            GetBasicDtoPagination
        >({
            query: (dto) => getSerialsQuery(dto, REQUESTS_PATH.SERIAL_NUMBERS),
            transformResponse: getTransformedSerialNumbersData
        }),
        preCheckByPart: builder.mutation<PreCheckResponse, PreCheckDTO>({
            query: (dto) => {
                return {
                    url: REQUESTS_PATH.PRE_CHECK,
                    method: 'POST',
                    body: dto
                };
            }
        }),
        submitFullOrderByPart: builder.mutation<
            SubmitFullOrderResponse,
            SubmitOrderDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.SUBMIT_FULL_ORDER,
                method: 'POST',
                body: dto
            }),
            extraOptions: {
                flowEnd: true
            }
        }),
        submitReducedOrder: builder.mutation<
            SubmitReducedOrderResponse,
            SubmitOrderDTO
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.SUBMIT_REDUCED_ORDER,
                method: 'POST',
                body: dto
            }),
            extraOptions: {
                flowEnd: true
            }
        })
    })
});

export const {
    usePreCheckByPartMutation,
    useGetOrderReferenceNumberLabelsMutation,
    useGetOutboundOrderPartsMutation,
    useGetServicesMutation,
    useGetSerialNumbersMutation,
    useSubmitFullOrderByPartMutation,
    useSubmitReducedOrderMutation
} = outboundOrderByPartApi;
