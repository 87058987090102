import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.d';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithIntercept } from 'APIServices/fetchBaseQuery';
import {
    AvailableTransferServiceDTO,
    REQUEST_PATH,
    REQUEST_PATH_DEFECTIVE
} from 'APIServices/materialsTransferByWarehouse/MaterialsTransferByWarehouse.types';
import { IQueryWithIntercept } from 'APIServices/queryWithIntercept';
import {
    GetWarehouseResponse,
    GetAccountWarehouseDTO,
    GetWarehouseTransformedResponse
} from 'APIServices/warehouse/WarehouseApi.types';
import { getServicesWithIds } from 'utils/helpers/activeOrder';
import {
    getSerialsQuery,
    getTransformedDataWithHazard,
    getTransformedSerialNumbersData
} from 'utils/helpers/apiFormatter';
import { getBasePaginationBody } from 'utils/helpers/getBasePaginationBody';
import {
    GetBasicDtoPagination,
    PreCheckDTO,
    PreCheckResponse,
    SubmitFullOrderResponse,
    SubmitOrderDTO,
    SubmitReducedOrderResponse
} from 'utils/types/api/api.types';
import { AvailableServicesResponse } from 'utils/types/deliveryServices/deliveryServices.types';
import {
    OrderReferenceLabelsDTO,
    OrderReferenceLabelsResponse
} from 'utils/types/general/stepper.types';
import {
    IGetPartTransformedResponse,
    GetSerialsTransformedResponse
} from 'utils/types/parts/parts.types';

const getCommonEndpoints = (
    builder: EndpointBuilder<IQueryWithIntercept, never, string>,
    requestsPath: typeof REQUEST_PATH | typeof REQUEST_PATH_DEFECTIVE
) => ({
    getWarehouseParts: builder.mutation<
        IGetPartTransformedResponse,
        GetBasicDtoPagination
    >({
        query: (dto) => {
            return {
                url: requestsPath.INVENTORY,
                method: 'POST',
                body: getBasePaginationBody({
                    ...dto,
                    filterFields: 'CustomDescription',
                    fixedSorter: 'partNumber'
                })
            };
        },
        transformResponse: getTransformedDataWithHazard
    }),
    getAccountWarehouse: builder.mutation<
        GetWarehouseTransformedResponse,
        GetAccountWarehouseDTO
    >({
        query(dto) {
            return {
                url: requestsPath.ACCOUNT_SSL,
                method: 'POST',
                body: dto
            };
        },
        extraOptions: {
            flowStart: true
        },
        transformResponse: (
            response: GetWarehouseResponse
        ): GetWarehouseTransformedResponse => {
            return {
                ...response,
                data: {
                    available: response.data?.available?.map((item) => ({
                        ...item,
                        id: item.sslId.toString()
                    })),
                    unavailable: response.data?.unavailable?.map((item) => ({
                        ...item,
                        id: item.sslId.toString()
                    }))
                }
            };
        }
    }),
    getServices: builder.mutation<
        AvailableServicesResponse,
        AvailableTransferServiceDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_SERVICES,
            method: 'POST',
            body: dto,
            headers: {
                'Api-Version': '3.0'
            }
        }),
        transformResponse(response: AvailableServicesResponse) {
            return getServicesWithIds(response) as AvailableServicesResponse;
        }
    }),
    getOrderReferenceNumberLabels: builder.mutation<
        OrderReferenceLabelsResponse,
        OrderReferenceLabelsDTO
    >({
        query: (dto) => ({
            url: requestsPath.ORDER_REFERENCE_LABELS,
            method: 'POST',
            body: dto
        })
    }),
    getSerialNumbers: builder.mutation<
        GetSerialsTransformedResponse,
        GetBasicDtoPagination
    >({
        query: (dto) => getSerialsQuery(dto, requestsPath.SERIAL_NUMBERS),
        transformResponse: getTransformedSerialNumbersData
    }),
    preCheckTransferByWarehouse: builder.mutation<
        PreCheckResponse,
        PreCheckDTO
    >({
        query: (dto) => {
            return {
                url: requestsPath.PRE_CHECK,
                method: 'POST',
                body: dto
            };
        }
    }),
    submitFullOrderByWarehouse: builder.mutation<
        SubmitFullOrderResponse,
        SubmitOrderDTO
    >({
        query: (dto) => ({
            url: requestsPath.SUBMIT_FULL_ORDER,
            method: 'POST',
            body: dto
        })
    }),
    submitReducedOrder: builder.mutation<
        SubmitReducedOrderResponse,
        SubmitOrderDTO
    >({
        query: (dto) => ({
            url: requestsPath.SUBMIT_REDUCED_ORDER,
            method: 'POST',
            body: dto
        })
    })
});

export const materialsTransferByWarehouseApi = createApi({
    reducerPath: 'materialsTransferByWarehouseApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, REQUEST_PATH)
});

export const materialsTransferByWarehouseDefectiveApi = createApi({
    reducerPath: 'materialsTransferByWarehouseDefectiveApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, REQUEST_PATH_DEFECTIVE)
});

export const {
    useGetWarehousePartsMutation,
    useGetAccountWarehouseMutation,
    useGetServicesMutation,
    useGetOrderReferenceNumberLabelsMutation,
    useGetSerialNumbersMutation,
    usePreCheckTransferByWarehouseMutation,
    useSubmitFullOrderByWarehouseMutation,
    useSubmitReducedOrderMutation
} = materialsTransferByWarehouseApi;

export const {
    useGetWarehousePartsMutation: useDefectiveGetWarehousePartsMutation,
    useGetAccountWarehouseMutation: useDefectiveGetAccountWarehouseMutation,
    useGetServicesMutation: useDefectiveGetServicesMutation,
    useGetOrderReferenceNumberLabelsMutation:
        useDefectiveGetOrderReferenceNumberLabelsMutation,
    useGetSerialNumbersMutation: useDefectiveGetSerialNumbersMutation,
    usePreCheckTransferByWarehouseMutation:
        useDefectivePreCheckTransferByWarehouseMutation,
    useSubmitFullOrderByWarehouseMutation:
        useDefectiveSubmitFullOrderByWarehouseMutation,
    useSubmitReducedOrderMutation: useDefectiveSubmitReducedOrderMutation
} = materialsTransferByWarehouseDefectiveApi;
