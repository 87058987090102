import React from 'react';

import { Col, Divider, Form, Row } from 'antd';

import { useUpdateUserSettingsMutation } from 'APIServices/users/Users.api';
import {
    DATE_FORMAT,
    MEASUREMENTS,
    TIME_FORMAT
} from 'APIServices/users/UsersApi.types';
import { Checkbox } from 'components/UI/atoms/checkbox/Checkbox';
import { Loader } from 'components/UI/atoms/loader/Loader';
import { Switch } from 'components/UI/atoms/switch/Switch';
import { FormSelect } from 'components/UI/molecules/form/formSelect/FormSelect';
import { FormTextInput } from 'components/UI/molecules/form/formTextInput/FormTextInput';
import { PanelBlock } from 'components/UI/organisms/adminPanel/components/panelBlock/PanelBlock';
import { BlockTitle } from 'components/UI/organisms/userSettings/blockTitle/BlockTitle';
import { InfoAlert } from 'components/UI/organisms/userSettings/infoAlert/InfoAlert';
import { RowWithRadioGroup } from 'components/UI/organisms/userSettings/rowWithRadioGroup/RowWithRadioGroup';
import {
    LOCAL_STORAGE_FIELDS,
    setLocalStorageItem
} from 'utils/helpers/localStorage';
import { useUserSettings } from 'utils/hooks/useUserSettings';

import styles from './UserSettings.module.less';

type FormValues = {
    firstName: string;
    lastName: string;
    email: string;
    profile: string;
    dateFormatKey: DATE_FORMAT;
    timeFormatKey: TIME_FORMAT;
    measurementKey: MEASUREMENTS;
    isDarkModeEnabled: boolean;
    isOrderWatchlistEnabled: boolean;
    isQuickAccessEnabled: boolean;
};

type SingleFieldValues<T> = {
    [K in keyof T]: { [P in K]: T[K] };
}[keyof T];

export const UserSettingsTemplate = () => {
    const [updateUserSettings] = useUpdateUserSettingsMutation();
    const {
        firstName,
        lastName,
        email,
        profileName,
        dateFormatKey,
        timeFormatKey,
        measurementKey,
        isDarkModeEnabled,
        loading,
        isOrderWatchlistEnabled = false,
        isQuickAccessEnabled = false
    } = useUserSettings();
    const [form] = Form.useForm();

    const initialValues: FormValues = {
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || '',
        profile: profileName || '',
        dateFormatKey: dateFormatKey || DATE_FORMAT.MONTH_DAY_YEAR,
        timeFormatKey: timeFormatKey || TIME_FORMAT['12_HOURS'],
        measurementKey: measurementKey || MEASUREMENTS.US,
        isDarkModeEnabled: isDarkModeEnabled || false,
        isOrderWatchlistEnabled,
        isQuickAccessEnabled
    };

    const onToggleDarkMode = (isDarkMode: boolean) => {
        document.documentElement.setAttribute(
            'data-theme',
            isDarkMode ? 'dark' : 'light'
        );
        setLocalStorageItem(LOCAL_STORAGE_FIELDS.DARK_MODE, String(isDarkMode));
    };

    const handleValuesChange = (
        values: SingleFieldValues<FormValues>,
        {
            dateFormatKey,
            timeFormatKey,
            measurementKey,
            isDarkModeEnabled,
            isOrderWatchlistEnabled,
            isQuickAccessEnabled
        }: FormValues
    ) => {
        updateUserSettings({
            dateFormatKey,
            timeFormatKey,
            measurementKey,
            isDarkModeEnabled,
            isOrderWatchlistEnabled,
            isQuickAccessEnabled
        });
    };

    return (
        <PanelBlock
            className={styles.UserSettings}
            contentClassName={styles.UserSettings__Content}
            title="User Settings"
            hideHeaderOnMobile={false}
            withDivider
        >
            {!loading ? (
                <Form
                    data-testid="user-settings-form"
                    className={styles.UserSettings__Form}
                    form={form}
                    onValuesChange={handleValuesChange}
                    initialValues={initialValues}
                >
                    <Row data-testid="user-info-section">
                        <Col xs={24} lg={4}>
                            <BlockTitle title="USER INFO" />
                        </Col>
                        <Col xs={24} lg={20}>
                            <Row gutter={[30, 16]}>
                                <Col xs={24} lg={12}>
                                    <FormTextInput
                                        title="First Name"
                                        fieldName="firstName"
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} lg={12}>
                                    <FormTextInput
                                        title="Last Name"
                                        fieldName="lastName"
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row
                                gutter={[30, 16]}
                                className={styles.UserSettings__MarginTop}
                            >
                                <Col xs={24} lg={12}>
                                    <FormTextInput
                                        title="Email"
                                        fieldName="email"
                                        disabled
                                    />
                                </Col>
                                {initialValues.profile && (
                                    <Col xs={24} lg={12}>
                                        <FormSelect
                                            title="Profile"
                                            fieldName="profile"
                                            disabled
                                        />
                                    </Col>
                                )}
                            </Row>
                            <InfoAlert
                                className={styles.UserSettings__MarginTop}
                                text="In case of a changing user info, please contact the Site Administrator"
                            />
                        </Col>
                    </Row>
                    <Divider className={styles.UserSettings__Divider} />
                    <RowWithRadioGroup
                        title="DATE FORMAT"
                        data={[
                            {
                                label: DATE_FORMAT.MONTH_DAY_YEAR,
                                value: DATE_FORMAT.MONTH_DAY_YEAR
                            },
                            {
                                label: DATE_FORMAT.DAY_MONTH_YEAR,
                                value: DATE_FORMAT.DAY_MONTH_YEAR
                            }
                        ]}
                        groupName="dateFormatKey"
                    />
                    <Row>
                        <Col xs={24}>
                            <RowWithRadioGroup
                                title="TIME FORMAT"
                                data={[
                                    {
                                        label: '12 hours',
                                        value: TIME_FORMAT['12_HOURS']
                                    },
                                    {
                                        label: '24 hours',
                                        value: TIME_FORMAT['24_HOURS']
                                    }
                                ]}
                                groupName="timeFormatKey"
                            />
                            <Row>
                                <Col xs={24} lg={{ span: 20, offset: 4 }}>
                                    <InfoAlert
                                        className={
                                            styles.UserSettings__MarginTopSm
                                        }
                                        text={
                                            <>
                                                The 12-hour format runs from 1
                                                AM to 12 PM (noon) and then from
                                                1 PM to 12 AM (midnight)
                                                <br />
                                                The 24-hour format uses the
                                                numbers 00:00 to 23:59 (midnight
                                                is 00:00)
                                            </>
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <RowWithRadioGroup
                                title="MEASUREMENTS"
                                data={[
                                    {
                                        label: MEASUREMENTS.US,
                                        value: MEASUREMENTS.US
                                    },
                                    {
                                        label: MEASUREMENTS.METRIC,
                                        value: MEASUREMENTS.METRIC
                                    }
                                ]}
                                groupName="measurementKey"
                            />
                            <Row>
                                <Col xs={24} lg={{ span: 20, offset: 4 }}>
                                    <InfoAlert
                                        className={
                                            styles.UserSettings__MarginTopSm
                                        }
                                        text={
                                            <>
                                                US measurements mean pounds and
                                                miles for weight and distance
                                                <br />
                                                Metric measurements mean
                                                kilograms and kilometers for
                                                weight and distance
                                            </>
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col xs={7} sm={4}>
                            <BlockTitle title="DARK MODE" withMargin={false} />
                        </Col>
                        <Col xs={17} sm={20}>
                            <Form.Item
                                name="isDarkModeEnabled"
                                id="isDarkModeEnabled"
                                valuePropName="checked"
                            >
                                <Switch
                                    containerClassName={
                                        styles.UserSettings__CustomSwitch
                                    }
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    onChange={onToggleDarkMode}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider className={styles.UserSettings__Divider} />

                    <Row align="middle">
                        <Col
                            xs={7}
                            sm={4}
                            className={styles.UserSettings__HomePage}
                        >
                            <BlockTitle
                                title="HOME PAGE"
                                withMargin={false}
                                withTopPosition={true}
                            />
                        </Col>
                        <div className={styles.HomeCustomize}>
                            <Form.Item
                                name="isQuickAccessEnabled"
                                id="isQuickAccessEnabled"
                                valuePropName="checked"
                            >
                                <Checkbox label="Quick Access" />
                            </Form.Item>

                            <Form.Item
                                name="isOrderWatchlistEnabled"
                                id="isOrderWatchlistEnabled"
                                valuePropName="checked"
                            >
                                <Checkbox label="Order Watchlist" />
                            </Form.Item>
                        </div>
                    </Row>
                </Form>
            ) : (
                <Loader fullHeight />
            )}
        </PanelBlock>
    );
};
