import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { MutationDefinition } from '@reduxjs/toolkit/query';

import { IQueryWithIntercept } from 'APIServices/queryWithIntercept';
import { DATE_FORMAT, TIME_FORMAT } from 'APIServices/users/UsersApi.types';
import { PROGRESS_STATUSES } from 'utils/helpers/orderStatus';
import {
    BasicPaginationResponse,
    RequestStatusWithData,
    RequestWithPagination
} from 'utils/types/api/api.types';
import {
    ListItem,
    Pagination,
    ListRefNumbers,
    Status
} from 'utils/types/general/general.types';

export enum REQUEST_PATH {
    GET_ORDERS = '/TrackOrders/GetOrders',
    GET_ORDER_TICKETS = '/TrackOrders/GetOrderTickets/:id',
    GET_ORDER_TICKET_COMMENTS = '/TrackOrders/GetTicketComments/:proNum',
    GET_ORDER_TYPES = '/TrackOrders/GetOrderTypes',
    GET_ORDER_DETAILS = '/TrackOrders/GetOrder/:id',
    GET_AVAILABLE_WAREHOUSES = '/TrackOrders/GetAvailableSsls',
    GET_TRACK_ORDER_SETTINGS = '/TrackOrders/GetSettings',
    GET_TICKET_MILESTONES = '/TrackOrders/GetTicketMilestones/:id',
    GET_TICKET_DOCUMENTS = '/TrackOrders/GetTicketDocuments/:id',
    GET_TICKET_DOCUMENT_FILE = '/TrackOrders/DownloadTicketDocument/:id',
    GET_PACKAGE_INFO = '/TrackOrders/GetPackageInfo/:id',
    GET_DOCUMENTS_TYPES = '/TrackOrders/GetAvailableDocumentTypes',
    GET_PACKAGE_PARTS = '/TrackOrders/GetParts',
    DELETE_DOCUMENT = '/TrackOrders/DeleteTicketDocuments/:id',
    GET_CARRIERS = '/TrackOrders/GetCarrierTracking',
    GET_CARRIER_PACKAGE_INFO = '/TrackOrders/GetCarrierPackageInfo',
    ADD_TO_WATCHLIST = '/OrderWatchlistItems/:id'
}

export interface OrderOriginDestination {
    isSsl: boolean;
    sslCode: string | null;
    sslAlias: string | null;
    countryName: string;
    countryCode: string;
    city: string;
    companyName: string | null;
}

export interface PartCount {
    good: number;
    bad: number;
    undetermined: number;
}

export interface DownloadDocumentsFileDTO {
    ticketDocumentHistoryId: string;
    fileName?: string;
}

export interface TrackOrderRaw {
    orderId: number;
    orderStatus: PROGRESS_STATUSES;
    orderType: string;
    referenceNumbers: string[];
    origin: OrderOriginDestination;
    destination: OrderOriginDestination;
    pickUpDateEstimated: string | null;
    pickUpDateActual: string | null;
    pickUpDateTimeZone: string | null;
    deliveryDateEstimated: string | null;
    deliveryDateActual: string | null;
    deliveryDateTimeZone: string | null;
    partsCount: PartCount;
    isInOrderWatchList: boolean;
}

export interface TransformedTrackOrderRaw
    extends Omit<TrackOrderRaw, 'orderStatus'> {
    status: PROGRESS_STATUSES;
}

export interface GetOrdersDTO extends Pick<Pagination, 'page' | 'pageSize'> {
    sorts: string;
    filters: string;
}

export interface TransformedTrackOrder extends TransformedTrackOrderRaw {
    id: string;
}

export enum ORDER_STATUS_FROM_RESPONSE {
    ON_TIME = 'onTime',
    DELAYED = 'delayed',
    PENDING = 'pending',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
    TOTAL = 'total'
}

interface GetOrderData extends BasicPaginationResponse {
    items: TrackOrderRaw[];
    statusTotals: Record<ORDER_STATUS_FROM_RESPONSE, number>;
}

interface GetTransformedOrderData
    extends Omit<GetOrderData, 'items' | 'statusTotals'> {
    items: TransformedTrackOrder[];
    statusTotals: Status[];
}

export type GetOrdersResponse = RequestStatusWithData<GetOrderData>;

export type GetOrdersTransformedResponse =
    RequestStatusWithData<GetTransformedOrderData>;

export type GetOrdersRequest = MutationActionCreatorResult<
    MutationDefinition<
        GetOrdersDTO,
        IQueryWithIntercept,
        never,
        GetOrdersTransformedResponse
    >
>;

export enum TicketDirections {
    INBOUND = 'Inbound',
    OUTBOUND = 'Outbound'
}

export interface OrderTicket {
    ticketId: number;
    proNum: string;
    sequence?: number;
    currentTicketFlag: boolean;
    direction: TicketDirections;
    ticketStatus: string;
    serviceLevel: string | null;
    carrier?: string;
    awbList: string | null;
    originCountry: string;
    originCity: string | null;
    originSSL: string | null;
    originSSLAlias: string | null;
    originLongitude: number | null;
    originLatitude: number | null;
    destinationCountry: string;
    destinationCity: string | null;
    destinationSSL: string | null;
    destinationSSLAlias: string | null;
    destinationLongitude: number | null;
    destinationLatitude: number | null;
    currentLocationLatitude: number | null;
    currentLocationLongitude: number | null;
    isTrackable?: boolean;
    originIsSsl: boolean;
    destinationIsSsl: boolean;
    freightAccount: string;
    dutiesAndTaxesAccount: string;
}

export type GetOrderTicketsResponse = RequestStatusWithData<OrderTicket[]>;

export interface GetOrderTicketCommentsDTO {
    dto: RequestWithPagination;
    proNum: string;
}

export interface TicketComment {
    date: string;
    timeZone: string;
    comment: string;
}

interface GetOrderTicketCommentsData extends BasicPaginationResponse {
    items: TicketComment[];
}

export interface OrderType {
    orderTypeId: number;
    description: string;
}

export interface OrderSetting {
    key: string;
    value: number;
}

export interface OrderDetailsOriginDestination {
    isSsl: boolean;
    sslCode: string | null;
    sslAlias: string | null;
    countryCode: string;
    countryName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postalCode: string;
    contact: string;
    phoneNumber: string;
    province: string;
    city: string;
    companyName: string;
}

interface TicketStatuses {
    id: number;
    description: string;
    order: number;
}

export interface TicketType extends OrderTicket {
    sequenceNumber: number;
    proNum: string;
    currentTicketFlag: boolean;
    ticketItemData?: TicketType;
    isTrackable: boolean;
    ticketStatuses: TicketStatuses[];
}

interface FormattedDate {
    date: string;
    isActual: boolean;
}

interface UserInfo {
    contact: string | undefined;
    phoneNumber: string | undefined;
}

export interface ValidatedDate {
    actualDate?: string;
    estimatedDate?: string;
    timeZone?: string | null;
    dateFormatKey?: DATE_FORMAT;
    timeFormatKey?: TIME_FORMAT;
}

export interface AddressInfoData {
    origin?: Omit<OrderDetailsOriginDestination, 'countryCode'>;
    pickUpDate?: FormattedDate;
    destination?: Omit<OrderDetailsOriginDestination, 'countryCode'>;
    userInfo?: UserInfo;
    deliveryDate?: FormattedDate;
}

export interface OrderMainData extends Pick<OrderDetailsData, 'tickets'> {
    referenceNumbers: ListRefNumbers;
    mainInfo: {
        account?: string;
        orderType?: string;
        orderStatus?: string;
    };
    addressInfo: AddressInfoData;
    isOriginWarehouse?: boolean;
    isDestinationWarehouse?: boolean;
}

export interface OrderDetailsData {
    orderId: number;
    accountId: number;
    accountNumber: string;
    subAccountId: number;
    subAccountNumber: string;
    orderStatus: string;
    orderType: string;
    firstProNumber: string;
    orderReferenceNumberLabel: ListItem | null;
    orderReferenceNumberLabel2: ListItem | null;
    orderReferenceNumberLabel3: ListItem | null;
    orderReferenceNumberLabel4: ListItem | null;
    origin: OrderDetailsOriginDestination;
    destination: OrderDetailsOriginDestination;
    pickUpDateEstimated: string;
    pickUpDateActual: string;
    deliveryDateEstimated: string;
    deliveryDateActual: string;
    createDate: string;
    partsBadCount: number;
    tickets?: TicketType[];
    deliveryDateTimeZone: string | null;
    pickUpDateTimeZone: string | null;
    subAccountDescription?: string;
    isInOrderWatchList: boolean;
}

export interface OrderWarehouseDataItem {
    availableSslList: {
        region: string;
        regionCode: string;
        availableCountries: {
            country: string;
            countryCode: string;
            sslList: {
                code: string;
                city: string;
                country: string;
            }[];
        }[];
    }[];
}

export interface MileStones {
    description: string;
    milestoneDate: string;
    timeZone: string;
    milestoneConfirmedBy: string;
    signatory: string;
    order: number;
}

export interface Documents {
    ticketDocumentId: number;
    ticketDocumentHistoryID: number;
    documentType: string;
    documentName: string;
    lastUpdate: string;
    lastUpdateTimeZone: string;
    updatedBy: string;
    isDeletable: boolean;
    isUpdatable: boolean;
}

export interface TransformedMileStones extends MileStones {
    id: string;
}

export interface TransformedDocuments extends Documents {
    id: string;
}
export interface MileStonesData {
    shipmentId: number | null;
    awb: string | null;
    milestones: MileStones[];
    packageId: number;
}

export interface DocumentsData {
    hasAvailableDocumentTypes: boolean;
    documents: Documents[];
}

export interface TransformedMileStonesData extends MileStonesData {
    milestones: TransformedMileStones[];
}

export interface TransformedDocumentsData extends DocumentsData {
    documents: TransformedDocuments[];
}

interface PackagePV {
    weight: number;
    weightUnit: string;
    dimensionUnit: string;
    height: number;
    length: number;
    width: number;
}

export interface PackageInfoData extends PackagePV {
    packageSequence: number;
    packageType: string;
}

export interface PackageInfoPart {
    condition: string;
    dangerousGoods: string;
    description: string;
    hazardClassId?: number;
    isClientUsingExpandedSerials: boolean;
    isConsumable: boolean;
    isDualUse: boolean;
    partId: number;

    partNumber: string;
    serialized: boolean;
    totalQuantity: number;
    type: string;
    unNumber: string | null;
    weight: number;
    weightPerPart: number;

    partItems: {
        referenceNumber: string;
        quantity: number;
        serialNumbers: {
            serialNumber: string;
            serialNumberExpanded: string;
        }[];
    }[];
}

export interface CarrierEvents {
    date: string;
    localTime: string;
    description: string;
    location: string;
    signatory: string;
    timeZone: string;
}

export interface TransformedCarrierEvent extends CarrierEvents {
    id: string;
}

export interface GetPackageInfoPartsData {
    defective: PackageInfoPart[] | null;
    good: PackageInfoPart[] | null;
    undetermined: PackageInfoPart[] | null;
}

export interface GetCarriersData extends BasicPaginationResponse {
    items: CarrierEvents[];
}

export interface GetTransformedCarriersData extends BasicPaginationResponse {
    items: TransformedCarrierEvent[];
}

export interface GetPackageInfoPartsDTO {
    ticketId: number;
    shipmentId?: number;
}

export interface GetCarriersList {
    filters: string;
    sorts: string;
    page: number;
    perPage: number;
}

export interface GetCarriersDTO {
    awb: string;
    proNumber: string;
}

export interface GetDocumentsTypesData {
    documentTypeId: number;
    name: string;
    description: string;
}

export type GetPackageInfoResponse = RequestStatusWithData<PackageInfoData>;
export type OrderDetailsDataResponse = RequestStatusWithData<OrderDetailsData>;
export type GetAvailableWarehousesResponse =
    RequestStatusWithData<OrderWarehouseDataItem>;
export type GetMileStonesResponse = RequestStatusWithData<MileStonesData[]>;
export type GetTransformedMileStonesResponse = RequestStatusWithData<
    TransformedMileStonesData[]
>;
export type GetDocumentsResponse = RequestStatusWithData<DocumentsData>;
export type GetTransformedDocumentsResponse =
    RequestStatusWithData<TransformedDocumentsData>;
export type GetOrderTicketCommentsResponse =
    RequestStatusWithData<GetOrderTicketCommentsData>;
export type OrderTypesResponse = RequestStatusWithData<OrderType[]>;
export type TrackOrderSettings = RequestStatusWithData<OrderSetting[]>;
export type GetPackageInfoPartsResponse =
    RequestStatusWithData<GetPackageInfoPartsData>;
export type GetDocumentsTypesResponse = RequestStatusWithData<
    GetDocumentsTypesData[]
>;
export type GetCarrierPackageInfoResponse = RequestStatusWithData<PackagePV>;
export type GetCarriersResponse = RequestStatusWithData<GetCarriersData>;
export type GetTransformedCarriersResponse =
    RequestStatusWithData<GetTransformedCarriersData>;
