import { ReactNode } from 'react';

import { InitialSearchParams } from 'utils/helpers/paramsHandler';
import { VoidFunction } from 'utils/types/general/general.types';

export interface FilterItem {
    filterName?: string;
    customId?: string;
    icon?: ReactNode;
    withArrow?: boolean | null;
    filterComponent?: ReactNode;
    isVisible: boolean;
    isSelected?: boolean;
    onClick?: VoidFunction;
}

export enum MIR_SEARCH_PARAMS {
    MIR_REASONS = 'mirReasons',
    CARRIERS = 'carriers',
    WAREHOUSES = 'warehouses'
}

export enum TRACKING_SEARCH_PARAMS {
    PART_CONDITION = 'partConditionTypes',
    DELIVERY_TYPES = 'deliveryTypes',
    ORIGIN_LOCATIONS = 'originLocations',
    DESTINATION_LOCATIONS = 'destinationLocations'
}

export interface MaterialsListSearchParams extends InitialSearchParams {
    [MIR_SEARCH_PARAMS.MIR_REASONS]?: string;
    [MIR_SEARCH_PARAMS.CARRIERS]?: string;
    [MIR_SEARCH_PARAMS.WAREHOUSES]?: string;
}

export interface DocumentModalData {
    mode: DocumentModalMode;
    documentType?: string;
    ticketDocumentHistoryID?: number;
}

export interface ConfirmModalData {
    docName: string;
    ticketDocumentHistoryId: number;
}

export enum DocumentModalMode {
    ADD = 'add',
    edit = 'edit'
}
