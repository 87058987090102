import { RefObject, useEffect, useState } from 'react';

import { BaseOptionType } from 'antd/lib/select';

import { OrderWarehouseDataItem } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { Account, SubAccount } from 'APIServices/users/UsersApi.types';
import {
    IComparedFilterValues,
    ISelectedFilters
} from 'components/UI/organisms/allFiltersWrapper/AllFiltersWrapper';
import { areUniqueArraysEqual } from 'utils/helpers/array';
import { capitalize } from 'utils/helpers/stringHelper';
import { useTreeData } from 'utils/hooks/useTreeData';
import { useUser } from 'utils/hooks/useUser';
import { VoidFunction } from 'utils/types/general/general.types';

interface TreeFilterReturnType {
    accounts: Account[] | undefined;
    accountsLoading: boolean;
    onTreeInit: VoidFunction;
    selectedKeys: string[];
    onClearClick?: (newSelectedKeys: string[]) => void;
    areSelectedKeysChanged: boolean;
    handleChange: (newValue: string[]) => void;
    allTreeFilterHandleChange: (newValue: string[]) => void;
    onRemoveSearchBadge: (value: string) => void;
    onRemoveFilterSearchBadge: (value: string) => void;
    treeData?: BaseOptionType[];
    popupRef: RefObject<HTMLDivElement>;
    getCustomPopupContainer: (triggerNode: HTMLElement) => HTMLElement;
    hasKeys?: boolean;
}

export const getSubAccountDescription = (
    id: number,
    data?: SubAccount[]
): string => {
    if (data?.length) {
        return data.find((item) => item.subAccountId === id)?.description || '';
    }
    return '';
};

export const getSubAccountIdBySubAccNumber = (
    subAccountNumber: string,
    data?: SubAccount[]
) => {
    if (data?.length) {
        return (
            data.find((item) => item.subAccountNumber === subAccountNumber)
                ?.subAccountId || null
        );
    }
    return null;
};

export const useAccountFilter = ({
    selectedFilterItems,
    isDefaultKeysDependency,
    setComparedResults,
    setNewFilters,
    selectInitialValue,
    data,
    filterType,
    compareType,
    isAccountAndSubsSetting
}: {
    selectedFilterItems: string[];
    isDefaultKeysDependency?: boolean;
    setComparedResults?: (newComparedValue: IComparedFilterValues) => void;
    setNewFilters?: (newFilter: ISelectedFilters) => void;
    selectInitialValue?: string[];
    data?: OrderWarehouseDataItem;
    filterType?: string;
    compareType?: string;
    isAccountAndSubsSetting: boolean;
}): TreeFilterReturnType => {
    const { accounts, accountsLoading = false, fetchAccounts } = useUser();

    const [treeData, setTreeData] = useState<
        {
            title: string;
            value: string;
            children: { title: string; value: string }[];
        }[]
    >([]);

    const getTreeData = async () => {
        if (isAccountAndSubsSetting) {
            const accounts = await fetchAccounts?.(true);

            const accountsData = accounts ? accounts.data : null;

            const dataByAccounts = accountsData
                ? accountsData.map(
                      ({ accountNumber, accountId, subAccounts }) =>
                          ({
                              title: accountNumber,
                              value: accountId.toString(),
                              children: subAccounts.map((el) => {
                                  const value = `${accountId}-${el.subAccountId}`;

                                  return {
                                      title: el.description,
                                      value
                                  };
                              })
                          } || [])
                  )
                : [];

            setTreeData(dataByAccounts);
        } else {
            const accountByAvailable = data?.availableSslList.map(
                ({ region, regionCode, availableCountries }) => ({
                    title: region,
                    value: regionCode,
                    children: availableCountries.map(
                        ({ country, countryCode, sslList }) => {
                            const countryNameCapitalized = capitalize(country);

                            const sslListData = sslList.map(
                                (warehouse) =>
                                    ({
                                        title: `${warehouse.code}, ${capitalize(
                                            warehouse?.city || ''
                                        )}, ${capitalize(
                                            warehouse?.country || ''
                                        )}`,
                                        value: `${regionCode}_${countryCode}_${warehouse.code}`
                                    } || [])
                            );

                            return {
                                title: countryNameCapitalized,
                                value: `${regionCode}_${countryCode}`,
                                children: sslListData
                            };
                        }
                    )
                })
            );

            accountByAvailable && setTreeData(accountByAvailable);
        }
    };

    useEffect(() => {
        getTreeData();
    }, [isAccountAndSubsSetting]);

    const {
        popupRef,
        getCustomPopupContainer,
        onTreeInit,
        handleChange,
        onRemoveSearchBadge,
        areSelectedKeysChanged,
        selectedKeys,
        onClearClick,
        hasKeys
    } = useTreeData({
        defaultSelectedKeys: selectedFilterItems,
        isDataLoading: accountsLoading,
        isDefaultKeysDependency
    });

    const onRemoveFilterSearchBadge = (value: string) => {
        const withoutRemoved = selectedKeys.filter((item) => item !== value);
        const isArraysEqual = areUniqueArraysEqual(
            selectInitialValue || [],
            withoutRemoved
        );
        setNewFilters?.({ [`${filterType}`]: withoutRemoved });
        setComparedResults?.({ [`${compareType}`]: isArraysEqual });
    };

    const allTreeFilterHandleChange = (newValue: string[]) => {
        handleChange(newValue);
        setNewFilters?.({ [`${filterType}`]: newValue });
        const isArraysEqual = areUniqueArraysEqual(
            selectInitialValue ?? [],
            newValue
        );
        setComparedResults?.({ [`${compareType}`]: isArraysEqual });
    };

    return {
        accounts,
        accountsLoading,
        areSelectedKeysChanged,
        onTreeInit,
        selectedKeys,
        onClearClick,
        handleChange,
        allTreeFilterHandleChange,
        onRemoveSearchBadge,
        onRemoveFilterSearchBadge,
        treeData,
        popupRef,
        hasKeys,
        getCustomPopupContainer
    };
};
