import { createApi } from '@reduxjs/toolkit/query/react';

import {
    Client,
    ClientProfile,
    REQUESTS_PATH
} from 'APIServices/clients/ClientsApi.types';
import { baseQueryWithIntercept } from 'APIServices/fetchBaseQuery';
import { AccountsSubAccounts } from 'APIServices/users/UsersApi.types';
import { RequestStatusWithData } from 'utils/types/api/api.types';

export const clientsApi = createApi({
    reducerPath: 'clientsApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getClients: builder.query<RequestStatusWithData<Client[]>, void>({
            query: () => ({ url: REQUESTS_PATH.CLIENTS })
        }),
        getAccountsAndSubs: builder.query<AccountsSubAccounts, number>({
            query: (clientId) => ({
                url: `${REQUESTS_PATH.GET_ACCOUNTS_AND_SUBS}/${clientId}`
            })
        }),
        getClientProfiles: builder.query<
            RequestStatusWithData<ClientProfile[]>,
            number
        >({
            query: (clientId) => ({
                url: clientId
                    ? `${REQUESTS_PATH.GET_CLIENT_PROFILES}?clientId=${clientId}`
                    : REQUESTS_PATH.GET_CLIENT_PROFILES
            })
        })
    })
});

export const {
    useGetClientsQuery,
    useLazyGetAccountsAndSubsQuery,
    useLazyGetClientProfilesQuery
} = clientsApi;
