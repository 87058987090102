import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithIntercept } from 'APIServices/fetchBaseQuery';
import {
    GetOrderTicketsResponse,
    GetOrdersDTO,
    GetOrdersResponse,
    GetOrdersTransformedResponse,
    OrderTypesResponse,
    REQUEST_PATH,
    OrderDetailsDataResponse,
    GetAvailableWarehousesResponse,
    GetOrderTicketCommentsResponse,
    GetOrderTicketCommentsDTO,
    TrackOrderSettings,
    GetTransformedMileStonesResponse,
    GetMileStonesResponse,
    GetPackageInfoResponse,
    GetPackageInfoPartsResponse,
    GetPackageInfoPartsDTO,
    GetTransformedDocumentsResponse,
    GetDocumentsResponse,
    GetDocumentsTypesResponse,
    DownloadDocumentsFileDTO,
    GetCarriersDTO,
    GetCarriersResponse,
    GetTransformedCarriersResponse,
    GetCarrierPackageInfoResponse,
    GetCarriersList
} from 'APIServices/trackOrders/TrackOrdersApi.types';
import { downloadBlob } from 'utils/helpers/downloadBlob';
import { getBasePaginationBody } from 'utils/helpers/getBasePaginationBody';
import {
    PROGRESS_STATUSES,
    mapStatusTotalsToStatuses
} from 'utils/helpers/orderStatus';
import { RequestStatusWithData } from 'utils/types/api/api.types';

export const trackOrdersApi = createApi({
    reducerPath: 'trackOrdersApi',
    baseQuery: baseQueryWithIntercept,
    tagTypes: ['DOCUMENTS_TABLE', 'MILESTONE_TABLE', 'GET_ORDER'],
    endpoints: (builder) => ({
        getOrders: builder.mutation<GetOrdersTransformedResponse, GetOrdersDTO>(
            {
                query: (dto) => {
                    return {
                        url: REQUEST_PATH.GET_ORDERS,
                        method: 'POST',
                        body: dto
                    };
                },
                transformResponse: (
                    response: GetOrdersResponse
                ): GetOrdersTransformedResponse => {
                    return {
                        ...response,
                        data: {
                            ...response.data,
                            items:
                                response.data?.items?.map((item) => ({
                                    ...item,
                                    status: item.orderStatus,
                                    id: item.orderId.toString()
                                })) || [],
                            statusTotals: response.data?.statusTotals
                                ? Object.entries(
                                      response.data?.statusTotals
                                  ).map(([key, value]) => ({
                                      label: mapStatusTotalsToStatuses(key),
                                      value
                                  }))
                                : Object.values(PROGRESS_STATUSES).map(
                                      (status) => ({ label: status, value: 0 })
                                  )
                        }
                    };
                }
            }
        ),
        getOrderTickets: builder.mutation<GetOrderTicketsResponse, string>({
            query: (id) => ({
                url: REQUEST_PATH.GET_ORDER_TICKETS.replace(':id', id),
                method: 'GET'
            })
        }),
        getOrderTicketComments: builder.mutation<
            GetOrderTicketCommentsResponse,
            GetOrderTicketCommentsDTO
        >({
            query: ({ proNum, dto }) => ({
                url: REQUEST_PATH.GET_ORDER_TICKET_COMMENTS.replace(
                    ':proNum',
                    proNum
                ),
                method: 'POST',
                body: getBasePaginationBody({
                    ...dto,
                    withoutBaseQueries: true,
                    filterFields: ''
                })
            })
        }),
        getOrderTypes: builder.query<OrderTypesResponse, void>({
            query: () => ({
                url: REQUEST_PATH.GET_ORDER_TYPES,
                method: 'GET'
            })
        }),
        getOrder: builder.query<OrderDetailsDataResponse, string>({
            query: (orderId) => ({
                url: REQUEST_PATH.GET_ORDER_DETAILS.replace(':id', orderId),
                method: 'GET'
            }),
            providesTags: ['GET_ORDER']
        }),
        getAvailableWarehouses: builder.query<
            GetAvailableWarehousesResponse,
            void
        >({
            query: () => ({
                url: REQUEST_PATH.GET_AVAILABLE_WAREHOUSES,
                method: 'GET'
            })
        }),
        getTrackOrderSettings: builder.query<TrackOrderSettings, void>({
            query: () => ({
                url: REQUEST_PATH.GET_TRACK_ORDER_SETTINGS,
                method: 'GET'
            })
        }),
        getTicketMileStones: builder.query<
            GetTransformedMileStonesResponse,
            string
        >({
            query: (proNumber) => ({
                url: REQUEST_PATH.GET_TICKET_MILESTONES.replace(
                    ':id',
                    proNumber
                ),
                method: 'GET'
            }),
            providesTags: ['MILESTONE_TABLE'],
            transformResponse(
                response: GetMileStonesResponse
            ): GetTransformedMileStonesResponse {
                return {
                    ...response,
                    data: response.data?.map((item) => {
                        return {
                            ...item,
                            milestones: item.milestones.map((el, index) => {
                                return {
                                    ...el,
                                    id: `${item.awb}_${index}`
                                };
                            })
                        };
                    })
                };
            }
        }),
        getTicketDocuments: builder.query<
            GetTransformedDocumentsResponse,
            string
        >({
            providesTags: [{ type: 'DOCUMENTS_TABLE' }],
            query: (proNumber) => ({
                url: REQUEST_PATH.GET_TICKET_DOCUMENTS.replace(
                    ':id',
                    proNumber
                ),
                method: 'GET'
            }),
            transformResponse(
                response: GetDocumentsResponse
            ): GetTransformedDocumentsResponse {
                return {
                    ...response,
                    data: {
                        ...response.data,
                        documents: response.data.documents.map(
                            (item, index) => {
                                return {
                                    ...item,
                                    id: `${item.ticketDocumentId}_${index}`
                                };
                            }
                        ),
                        hasAvailableDocumentTypes:
                            response.data.hasAvailableDocumentTypes
                    }
                };
            }
        }),
        getDownloadDocument: builder.mutation<File, DownloadDocumentsFileDTO>({
            query: (dto) => ({
                url: REQUEST_PATH.GET_TICKET_DOCUMENT_FILE.replace(
                    ':id',
                    dto.ticketDocumentHistoryId
                ),
                headers: {
                    responseType: 'blob',
                    'content-type': 'application/octet-stream'
                },
                responseHandler: async (response) =>
                    downloadBlob(response, dto.fileName)
            })
        }),
        getPackageInfo: builder.query<GetPackageInfoResponse, string>({
            query: (packageId) => ({
                url: REQUEST_PATH.GET_PACKAGE_INFO.replace(':id', packageId)
            })
        }),
        getDocumentsType: builder.query<GetDocumentsTypesResponse, void>({
            query: () => ({
                url: REQUEST_PATH.GET_DOCUMENTS_TYPES
            })
        }),
        getPackageInfoParts: builder.mutation<
            GetPackageInfoPartsResponse,
            GetPackageInfoPartsDTO
        >({
            query: (dto) => ({
                url: REQUEST_PATH.GET_PACKAGE_PARTS,
                method: 'POST',
                body: dto
            })
        }),
        deleteDocument: builder.mutation<
            GetTransformedDocumentsResponse,
            number
        >({
            query: (id) => ({
                url: REQUEST_PATH.DELETE_DOCUMENT.replace(':id', String(id)),
                method: 'DELETE'
            }),
            invalidatesTags: ['DOCUMENTS_TABLE']
        }),
        getCarriers: builder.mutation<
            GetTransformedCarriersResponse,
            GetCarriersList
        >({
            query: (dto) => ({
                url: REQUEST_PATH.GET_CARRIERS,
                method: 'POST',
                body: dto
            }),
            transformResponse(
                response: GetCarriersResponse
            ): GetTransformedCarriersResponse {
                return {
                    ...response,
                    data: {
                        ...response.data,
                        items: response.data.items.map((item, i) => ({
                            ...item,
                            id: `${item.description}-${i}`
                        }))
                    }
                };
            }
        }),
        getCarrierPackageInfo: builder.mutation<
            GetCarrierPackageInfoResponse,
            GetCarriersDTO
        >({
            query: (dto) => ({
                url: REQUEST_PATH.GET_CARRIER_PACKAGE_INFO,
                method: 'POST',
                body: dto
            })
        }),

        addToWatchList: builder.mutation<RequestStatusWithData<null>, string>({
            query: (orderId) => ({
                url: REQUEST_PATH.ADD_TO_WATCHLIST.replace(':id', orderId),
                method: 'POST'
            }),
            invalidatesTags: ['GET_ORDER']
        }),

        deleteFromWatchList: builder.mutation<
            RequestStatusWithData<null>,
            string
        >({
            query: (orderId) => ({
                url: REQUEST_PATH.ADD_TO_WATCHLIST.replace(':id', orderId),
                method: 'DELETE'
            }),
            invalidatesTags: ['GET_ORDER']
        })
    })
});

export const {
    useGetOrdersMutation,
    useGetOrderTicketsMutation,
    useGetOrderTicketCommentsMutation,
    useGetOrderTypesQuery,
    useGetAvailableWarehousesQuery,
    useGetTrackOrderSettingsQuery,
    useLazyGetOrderQuery,
    useGetTicketMileStonesQuery,
    useGetTicketDocumentsQuery,
    useGetDownloadDocumentMutation,
    useLazyGetPackageInfoQuery,
    useGetPackageInfoPartsMutation,
    useGetCarriersMutation,
    useGetDocumentsTypeQuery,
    useDeleteDocumentMutation,
    useGetCarrierPackageInfoMutation,
    useAddToWatchListMutation,
    useDeleteFromWatchListMutation
} = trackOrdersApi;
