import { Account } from 'APIServices/users/UsersApi.types';
import { getSubAccountDescription } from 'components/UI/organisms/trackOrders/orderList/components/accountFilter/useAccountFilter';
import { SelectedFilterItems } from 'utils/hooks/useOrdersListFilters';

export interface SelectedAccountsAndSubsData {
    selectedAccountsAndSubs: SelectedFilterItems;
    onRemoveAccountsAndSubs: (id: string) => void;
}

export const useSelectedAccountsAndSubs = ({
    data,
    rawData,
    onApply
}: {
    data?: string[];
    rawData?: Account[];
    onApply: (data: string[]) => void;
}): SelectedAccountsAndSubsData => {
    const accountMap = rawData
        ? new Map(rawData.map((account) => [account.accountId, account]))
        : new Map();

    const selectedAccountsAndSubs = (
        data?.map((item) => {
            const [accId, subId] = item.split('-').map(Number);
            const account = accountMap.get(accId);

            if (!account) {
                return null;
            }

            const title = subId
                ? getSubAccountDescription(subId, account.subAccounts)
                : account.accountNumber.toString();

            return {
                title: title,
                value: item
            };
        }) || []
    ).filter(Boolean) as SelectedFilterItems;

    const onRemoveAccountsAndSubs = (idToRemove: string) => {
        const isSubAccount = idToRemove.includes('-');
        const withoutRemoved = (data || []).filter((item) => {
            if (isSubAccount) {
                return item !== idToRemove;
            } else {
                const [accountId] = item.split('-');
                return accountId !== idToRemove;
            }
        });
        onApply(withoutRemoved);
    };

    return {
        selectedAccountsAndSubs,
        onRemoveAccountsAndSubs
    };
};
