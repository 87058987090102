import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.d';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { bulkResponseHandler } from 'APIServices/bulkResponseHandler';
import { baseQueryWithIntercept } from 'APIServices/fetchBaseQuery';
import {
    REQUESTS_PATH,
    REQUESTS_PATH_DEFECTIVE
} from 'APIServices/outboundByBulk/OutboundByBulk.type';
import { IQueryWithIntercept } from 'APIServices/queryWithIntercept';
import { getServicesWithIds } from 'utils/helpers/activeOrder';
import { IRequestStatusWithEmptyData } from 'utils/types/api/api.types';
import {
    BULK_TEMPLATES_NAMES,
    ColumnMappingDTO,
    ColumnMappingResponse,
    DownloadTemplateDTO,
    IGetAvailableServicesDTO,
    IGetAvailableServicesResponse,
    IGroupingOrdersDTO,
    IGroupingOrdersResponse,
    ISubmitOrdersDTO,
    IValidateFileDTO,
    IValidateFileResponse
} from 'utils/types/bulkOrder/bulk.types';

const getCommonEndpoints = (
    builder: EndpointBuilder<IQueryWithIntercept, never, string>,
    isDefective: boolean
) => {
    const requestsPath = isDefective ? REQUESTS_PATH_DEFECTIVE : REQUESTS_PATH;
    const fileName = isDefective
        ? BULK_TEMPLATES_NAMES.OUTBOUND_DEFECTIVE
        : BULK_TEMPLATES_NAMES.OUTBOUND;

    return {
        getDownloadOutboundTemplate: builder.mutation<
            File,
            DownloadTemplateDTO
        >({
            query: (dto) => ({
                url: requestsPath.DOWNLOAD_TEMPLATE,
                headers: {
                    responseType: 'blob',
                    'content-type': 'application/octet-stream'
                },
                responseHandler: async (response) =>
                    bulkResponseHandler(response, dto, fileName),
                cache: 'no-cache',
                method: 'POST',
                body: dto
            })
        }),
        resetOutboundFile: builder.mutation<
            IRequestStatusWithEmptyData,
            string
        >({
            query: (id) => ({
                url: requestsPath.RESET_FILE.replace(':id', id),
                method: 'DELETE'
            }),
            extraOptions: {
                flowEnd: true
            }
        }),
        getOutboundColumnsMapping: builder.mutation<
            ColumnMappingResponse,
            ColumnMappingDTO
        >({
            query: (dto) => ({
                url: requestsPath.GET_COLUMNS_MAPPING,
                method: 'POST',
                body: dto,
                cache: 'no-cache'
            })
        }),
        validateOutboundFile: builder.mutation<
            IValidateFileResponse,
            IValidateFileDTO
        >({
            query: (dto) => ({
                url: requestsPath.VALIDATE_FILE,
                method: 'POST',
                body: dto,
                cache: 'no-cache'
            })
        }),
        getOutboundGroupingOrders: builder.mutation<
            IGroupingOrdersResponse,
            IGroupingOrdersDTO
        >({
            query: (dto) => ({
                url: requestsPath.GET_GROUPING,
                method: 'POST',
                body: dto,
                cache: 'no-cache',
                headers: {
                    'Api-Version': '3.0'
                }
            })
        }),
        getOutboundAvailableServices: builder.mutation<
            IGetAvailableServicesResponse,
            IGetAvailableServicesDTO
        >({
            query: (dto) => ({
                url: requestsPath.GET_AVAILABLE_SERVICES,
                method: 'POST',
                body: dto,
                cache: 'no-cache',
                headers: {
                    'Api-Version': '3.0'
                }
            }),
            transformResponse(response: IGetAvailableServicesResponse) {
                return getServicesWithIds(
                    response
                ) as IGetAvailableServicesResponse;
            }
        }),
        submitOutboundOrders: builder.mutation<
            IRequestStatusWithEmptyData,
            ISubmitOrdersDTO
        >({
            query: (dto) => ({
                url: requestsPath.SUBMIT_ORDERS,
                method: 'POST',
                body: dto,
                cache: 'no-cache',
                headers: {
                    'Api-Version': '3.0'
                }
            }),
            extraOptions: {
                flowEnd: true
            }
        })
    };
};

export const outboundByBulkApi = createApi({
    reducerPath: 'outboundByBulkApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, false)
});
export const outboundByBulkDefectiveApi = createApi({
    reducerPath: 'outboundByBulkDefectiveApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, true)
});

export const {
    useGetDownloadOutboundTemplateMutation,
    useResetOutboundFileMutation,
    useGetOutboundColumnsMappingMutation,
    useValidateOutboundFileMutation,
    useGetOutboundGroupingOrdersMutation,
    useSubmitOutboundOrdersMutation,
    useGetOutboundAvailableServicesMutation
} = outboundByBulkApi;

export const {
    useGetDownloadOutboundTemplateMutation:
        useDefectiveGetDownloadOutboundTemplateMutation,
    useResetOutboundFileMutation: useDefectiveResetOutboundFileMutation,
    useGetOutboundColumnsMappingMutation:
        useDefectiveGetOutboundColumnsMappingMutation,
    useValidateOutboundFileMutation: useDefectiveValidateOutboundFileMutation,
    useGetOutboundGroupingOrdersMutation:
        useGetDefectiveOutboundGroupingOrdersMutation,
    useSubmitOutboundOrdersMutation: useSubmitDefectiveOutboundOrdersMutation,
    useGetOutboundAvailableServicesMutation:
        useGetDefectiveOutboundAvailableServicesMutation
} = outboundByBulkDefectiveApi;
