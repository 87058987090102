import { useEffect } from 'react';

import { DateTime } from 'luxon';
import { Location, useLocation } from 'react-router-dom';

import { useGetOrderTypesQuery } from 'APIServices/trackOrders/TrackOrders.api';
import {
    GetAvailableWarehousesResponse,
    GetOrdersDTO,
    OrderType
} from 'APIServices/trackOrders/TrackOrdersApi.types';
import { Account } from 'APIServices/users/UsersApi.types';
import { RangeNullableDates } from 'components/UI/molecules/rangePicker/RangePicker';
import {
    checkIfDefaultPeriodSelected,
    getDateFilterData,
    getFilters,
    getWarehousesFilter,
    IDateFilterData,
    WAREHOUSE_FILTER_PREFIX
} from 'components/UI/organisms/trackOrders/orderList/ordersListUrlUtils';
import { useOrdersListUrl } from 'components/UI/organisms/trackOrders/orderList/useOrdersListUrl';
import {
    deliveryTypes,
    ORDER_DATE_FILTER_TYPE,
    orderDateFilterTypesData,
    partConditionTypes
} from 'utils/constants/trackOrdersMockedData';
import {
    getDateString,
    getLowercaseDateFormat
} from 'utils/helpers/dateAndTimeFormatters';
import { getBasicSort } from 'utils/helpers/getBasePaginationBody';
import { PROGRESS_STATUSES } from 'utils/helpers/orderStatus';
import { onAccountChangeWithParams } from 'utils/helpers/paramsHandler';
import { useLastUpdate } from 'utils/hooks/useLastUpdate';
import { useSearchFilter } from 'utils/hooks/useSearchFilter';
import { useSelectedAccountsAndSubs } from 'utils/hooks/useSelectedAccountsAndSubs';
import { useUser } from 'utils/hooks/useUser';
import { useUserSettings } from 'utils/hooks/useUserSettings';
import {
    Pagination,
    SORTING_DIRECTION,
    VoidFunction
} from 'utils/types/general/general.types';
import { TRACKING_SEARCH_PARAMS } from 'utils/types/mirAndTracking/MirAndTracking.types';

export type SelectedFilterItems = {
    title: string;
    value: string;
}[];

interface IOrdersListState extends Location {
    state: { redirectFromWatchList: boolean };
}

const getSelectedWarehouses = (keys: string[] = []) =>
    keys.map((item) => {
        const splitValue = item.split('_');

        return { title: splitValue[splitValue.length - 1], value: item };
    });

interface OrdersListReturnType extends Pagination {
    searchQuery?: string;
    searchInputValue?: string;
    onChangeSearch: (value: string) => void;
    onSubmitSearch: (value: string) => void;
    onSearchInputClean: VoidFunction;
    lastUpdated?: string;
    onRefreshClick: VoidFunction;
    applyOrderTypes: (orderTypes: string[]) => void;
    applyAccountsAndSubAccounts: (accounts: string[]) => void;
    onRemoveOrderType: (orderType: string) => void;
    onRemoveAccountsAndSubs: (id: string) => void;
    selectedOrderTypes?: string[];
    selectedPartConditionTypes?: string[];
    selectedDeliveryTypes?: string[];
    orderTypes?: OrderType[];
    partConditionTypesList?: OrderType[];
    deliveryTypesList?: OrderType[];
    selectedFilters: boolean;
    hasSelectedAccounts: boolean;
    hasSelectedOriginLocations: boolean;
    hasSelectedDestinationLocations: boolean;
    clearAllSelectedFilters: VoidFunction;
    onResetPeriod: VoidFunction;
    dateFilterData: IDateFilterData;
    minAvailableDateRange: Date;
    onChangeDates: (dates: RangeNullableDates, dateType: string) => void;
    selectedDates: string[];
    selectedDatesLabel: string;
    accounts: Account[] | undefined;
    accountsAndSubs?: string[];
    originLocations?: string[];
    selectedOriginLocations?: SelectedFilterItems;
    destinationLocations?: string[];
    selectedDestinationLocations?: SelectedFilterItems;
    selectedAccountsAndSubs: SelectedFilterItems;
    onStatusSelect: (status: PROGRESS_STATUSES) => void;
    showDangerousGoods?: string;
    onRemoveDangerousGoodsParam: (value: string) => void;
    onRemovePartConditionParam: (value: string) => void;
    onRemoveDeliveryParam: (value: string) => void;
    onRemoveOriginLocationsParam: (value: string) => void;
    onRemoveDestinationLocationsParam: (value: string) => void;
    status?: string;
    warehousesData?: GetAvailableWarehousesResponse;
    isDestinationsLoading?: boolean;
    showWatchList?: string;
    onRemoveWatchListParam: VoidFunction;
}

export const getSortingByDateType = (dateType: string) => {
    const sortObj = {
        field: Object.values<string>(ORDER_DATE_FILTER_TYPE).includes(dateType)
            ? dateType
            : ORDER_DATE_FILTER_TYPE.LATEST_EVENT,
        direction: SORTING_DIRECTION.DESC
    };

    return getBasicSort(sortObj);
};

export const useOrdersListFilters = (
    getData: (config: GetOrdersDTO) => void,
    orderCacheId: string | undefined
): OrdersListReturnType => {
    const { data: orderTypesData } = useGetOrderTypesQuery();
    const { accounts: accountsRawData, fetchAccounts } = useUser();
    const orderTypes = orderTypesData?.data;
    const partConditionTypesList = partConditionTypes;
    const deliveryTypesList = deliveryTypes;
    const location = useLocation() as IOrdersListState;
    const isRedirectFromWatchList = location?.state?.redirectFromWatchList;

    useEffect(() => {
        fetchAccounts?.(true);
    }, []);

    const {
        setParams,
        pagination,
        sorting,
        removeParam,
        orderTypes: selectedOrderTypes,
        partConditionTypes: selectedPartConditionTypes,
        deliveryTypes: selectedDeliveryTypes,
        dateFilterData,
        minAvailableDateRange,
        search,
        filters,
        accountsAndSubs,
        originLocations,
        destinationLocations,
        status,
        warehousesData,
        isDestinationsLoading,
        showDangerousGoods: dangerousGoodsSelectedState,
        showWatchList: watchListSelectedState
    } = useOrdersListUrl();

    const ordersConfig: GetOrdersDTO = {
        filters,
        sorts: getBasicSort(sorting),
        page: pagination.page,
        pageSize: pagination.pageSize
    };

    const { searchValue, onChangeSearch, onSearchInputClean, onSubmitSearch } =
        useSearchFilter({
            initialValue: search,
            setParams,
            removeParam,
            getData,
            config: ordersConfig,
            sorting,
            status: PROGRESS_STATUSES.TOTAL,
            getFilters,
            defaultDateRangeInDays: dateFilterData?.defaultDateRangeDays
        });

    const { setLastUpdated, fromLastRefresh } = useLastUpdate();

    const { dateFormatKey } = useUserSettings();

    const currentDate = DateTime.now();
    const watchlistDateFrom = currentDate.minus({ year: 1 }).toISODate();
    const watchlistDateTo = currentDate.toISODate();
    const watchlistDateType = 'CreateDate';

    const watchlistConfig: GetOrdersDTO = {
        filters: getFilters({
            showWatchList: 'true',
            status: PROGRESS_STATUSES.TOTAL,
            date: getDateFilterData({
                dateFrom: watchlistDateFrom,
                dateTo: watchlistDateTo,
                dateType: watchlistDateType
            }).filterData
        }),
        sorts: '-CreateDate',
        page: 1,
        pageSize: 10
    };

    useEffect(() => {
        const chosenConfig = isRedirectFromWatchList
            ? watchlistConfig
            : ordersConfig;

        if (isRedirectFromWatchList) {
            setParams({
                sorts: '-CreateDate',
                dateFrom: watchlistDateFrom,
                dateTo: watchlistDateTo,
                dateType: watchlistDateType,
                page: 1,
                pageSize: 10,
                showWatchList: 'true'
            });
        }

        if (accountsRawData && warehousesData) {
            getData({ ...chosenConfig });

            setLastUpdated(Date.now());
        }
    }, [
        accountsRawData,
        warehousesData,
        ordersConfig.filters,
        watchlistConfig.filters,
        pagination.page,
        pagination.pageSize,
        sorting.field,
        sorting.direction
    ]);

    useEffect(() => {
        setLastUpdated(Date.now());
    }, [orderCacheId]);

    const onRefreshClick = () => {
        getData({
            ...ordersConfig,
            page: 1
        });

        setParams({ page: 1 });
        setLastUpdated(Date.now());
    };

    const applyOrderTypes = (types: string[]) => {
        if (!types.length) {
            removeParam(['orderTypes', 'page']);
        } else {
            setParams({ orderTypes: types.join(','), page: 1 });
        }

        getData({
            ...ordersConfig,
            filters: getFilters({
                orderTypes: types,
                partConditionTypes: selectedPartConditionTypes,
                deliveryTypes: selectedDeliveryTypes,
                date: getDateFilterData({
                    dateFrom: dateFilterData.from,
                    dateTo: dateFilterData.to,
                    dateType: dateFilterData.dateType
                }).filterData,
                showWatchList: watchListSelectedState,
                accountsAndSubs,
                originLocations,
                destinationLocations,
                status,
                accounts: accountsRawData,
                warehouses: warehousesData?.data
            }),
            page: 1
        });
    };

    const onRemoveOrderType = (type: string) => {
        const withoutRemoved =
            selectedOrderTypes?.filter((orderType) => orderType !== type) || [];
        applyOrderTypes(withoutRemoved);
    };

    const onChangeDates = (
        [from, to]: RangeNullableDates,
        dateType: string
    ) => {
        const dateFrom = getDateString(from);
        const dateTo = getDateString(to);
        const sorting = getSortingByDateType(dateType);

        setParams({ sorts: sorting, dateFrom, dateTo, dateType, page: 1 });

        getData({
            ...ordersConfig,
            page: 1,
            sorts: sorting,
            filters: getFilters({
                date: getDateFilterData({ dateFrom, dateTo, dateType })
                    .filterData,
                orderTypes: selectedOrderTypes,
                partConditionTypes: selectedPartConditionTypes,
                deliveryTypes: selectedDeliveryTypes,
                showWatchList: watchListSelectedState,
                accountsAndSubs,
                originLocations,
                destinationLocations,
                accounts: accountsRawData,
                status,
                warehouses: warehousesData?.data
            })
        });
    };

    const removeAllFilterParams = () =>
        removeParam([
            'dateFrom',
            'dateTo',
            'dateType',
            'orderTypes',
            'partConditionTypes',
            'deliveryTypes',
            'accountsAndSubs',
            'originLocations',
            'destinationLocations',
            'showDangerousGoods',
            'status',
            'page',
            'sorts',
            'showWatchList'
        ]);

    const onRemoveDangerousGoodsParam = () => {
        removeParam(['showDangerousGoods', 'page']);

        getData({
            ...ordersConfig,
            filters: getFilters({
                orderTypes: selectedOrderTypes,
                partConditionTypes: selectedPartConditionTypes,
                deliveryTypes: selectedDeliveryTypes,
                date: getDateFilterData({
                    dateFrom: dateFilterData.from,
                    dateTo: dateFilterData.to,
                    dateType: dateFilterData.dateType
                }).filterData,
                accountsAndSubs,
                originLocations,
                destinationLocations,
                showWatchList: watchListSelectedState,
                status,
                accounts: accountsRawData,
                warehouses: warehousesData?.data
            }),
            page: 1
        });
    };

    const onRemoveWatchListParam = () => {
        removeParam(['showWatchList', 'page']);

        getData({
            ...ordersConfig,
            filters: getFilters({
                orderTypes: selectedOrderTypes,
                partConditionTypes: selectedPartConditionTypes,
                deliveryTypes: selectedDeliveryTypes,
                date: getDateFilterData({
                    dateFrom: dateFilterData.from,
                    dateTo: dateFilterData.to,
                    dateType: dateFilterData.dateType
                }).filterData,
                accountsAndSubs,
                showDangerousGoods: dangerousGoodsSelectedState,
                originLocations,
                destinationLocations,
                status,
                accounts: accountsRawData,
                warehouses: warehousesData?.data
            }),
            page: 1
        });
    };

    const applyBasicFilter = (
        data: string[],
        paramName: TRACKING_SEARCH_PARAMS
    ) => {
        if (!data.length) {
            removeParam([paramName, 'page']);
        } else {
            let formattedData;

            if (paramName === TRACKING_SEARCH_PARAMS.DESTINATION_LOCATIONS) {
                formattedData = getWarehousesFilter(
                    WAREHOUSE_FILTER_PREFIX.DESTINATION,
                    data,
                    warehousesData?.data
                );
            } else if (paramName === TRACKING_SEARCH_PARAMS.ORIGIN_LOCATIONS) {
                formattedData = getWarehousesFilter(
                    WAREHOUSE_FILTER_PREFIX.ORIGIN,
                    data,
                    warehousesData?.data
                );
            } else {
                formattedData = data.join(',');
            }

            setParams({ [paramName]: formattedData, page: 1 });
        }

        const filters = getFilters({
            orderTypes: selectedOrderTypes,
            partConditionTypes: selectedPartConditionTypes,
            deliveryTypes: selectedDeliveryTypes,
            date: getDateFilterData({
                dateFrom: dateFilterData.from,
                dateTo: dateFilterData.to,
                dateType: dateFilterData.dateType
            }).filterData,
            accountsAndSubs,
            showDangerousGoods: dangerousGoodsSelectedState,
            showWatchList: watchListSelectedState,
            originLocations,
            destinationLocations,
            status,
            accounts: accountsRawData,
            warehouses: warehousesData?.data,
            [paramName]: data
        });

        getData({ ...ordersConfig, page: 1, filters });
    };

    const onRemoveBasicFilter = (
        itemToDelete: string,
        paramName: TRACKING_SEARCH_PARAMS,
        data?: string[]
    ) => {
        const filteredData = data?.filter((item) => item !== itemToDelete);

        if (filteredData) {
            applyBasicFilter(filteredData, paramName);
        }
    };

    const onRemoveOriginLocationsParam = (itemToDelete: string) => {
        onRemoveBasicFilter(
            itemToDelete,
            TRACKING_SEARCH_PARAMS.ORIGIN_LOCATIONS,
            originLocations
        );
    };

    const onRemoveDestinationLocationsParam = (itemToDelete: string) => {
        onRemoveBasicFilter(
            itemToDelete,
            TRACKING_SEARCH_PARAMS.DESTINATION_LOCATIONS,
            destinationLocations
        );
    };

    const onRemovePartConditionParam = (itemToDelete: string) => {
        onRemoveBasicFilter(
            itemToDelete,
            TRACKING_SEARCH_PARAMS.PART_CONDITION,
            selectedPartConditionTypes
        );
    };

    const onRemoveDeliveryParam = (itemToDelete: string) => {
        onRemoveBasicFilter(
            itemToDelete,
            TRACKING_SEARCH_PARAMS.DELIVERY_TYPES,
            selectedDeliveryTypes
        );
    };

    const clearAllSelectedFilters = () => {
        removeAllFilterParams();
        getData({
            ...ordersConfig,
            sorts: '-LatestMilestoneDate',
            filters: getFilters(
                { status },
                false,
                dateFilterData.defaultDateRangeDays
            ),
            page: 1
        });
    };

    const onResetPeriod = () => {
        removeParam(['dateFrom', 'dateTo', 'dateType', 'sorts', 'page']);

        getData({
            ...ordersConfig,
            sorts: '-LatestMilestoneDate',
            filters: getFilters({
                date: getDateFilterData({
                    defaultDateRangeDays: dateFilterData.defaultDateRangeDays
                }).filterData,
                orderTypes: selectedOrderTypes,
                partConditionTypes: selectedPartConditionTypes,
                deliveryTypes: selectedDeliveryTypes,
                accountsAndSubs,
                originLocations,
                destinationLocations,
                accounts: accountsRawData,
                warehouses: warehousesData?.data,
                showWatchList: watchListSelectedState,
                status
            }),
            page: 1
        });
    };

    const selectedDestinationLocations =
        getSelectedWarehouses(destinationLocations);
    const selectedOriginLocations = getSelectedWarehouses(originLocations);

    const selectedDates = [
        `${DateTime.fromSQL(dateFilterData.from).toFormat(
            getLowercaseDateFormat({ dateFormatKey })
        )} - ${DateTime.fromSQL(dateFilterData.to).toFormat(
            getLowercaseDateFormat({ dateFormatKey })
        )}`
    ];

    const selectedDatesLabel =
        orderDateFilterTypesData.find(
            ({ value }) => value === dateFilterData.dateType
        )?.label || '';

    const hasSelectedDatesAndRange = !checkIfDefaultPeriodSelected({
        defaultDateRangeDays: dateFilterData.defaultDateRangeDays,
        from: dateFilterData.from,
        to: dateFilterData.to,
        dateType: dateFilterData.dateType
    });
    const hasSelectedAccounts = !!accountsAndSubs?.length;
    const hasSelectedOriginLocations = !!selectedOriginLocations?.length;
    const hasSelectedDestinationLocations =
        !!selectedDestinationLocations?.length;
    const hasSelectedDeliveryTypes = !!selectedDeliveryTypes?.length;
    const hasSelectedPartConditionTypes = !!selectedPartConditionTypes?.length;

    const applyAccountsAndSubAccounts = (data: string[]) => {
        const filters = getFilters({
            accountsAndSubs: data,
            originLocations,
            destinationLocations,
            accounts: accountsRawData,
            date: getDateFilterData({
                dateFrom: dateFilterData.from,
                dateTo: dateFilterData.to,
                dateType: dateFilterData.dateType
            }).filterData,
            orderTypes: selectedOrderTypes,
            partConditionTypes: selectedPartConditionTypes,
            deliveryTypes: selectedDeliveryTypes,
            showDangerousGoods: dangerousGoodsSelectedState,
            showWatchList: watchListSelectedState,
            status,
            warehouses: warehousesData?.data
        });

        onAccountChangeWithParams({
            data,
            removeParam,
            setParams,
            config: ordersConfig,
            getData,
            filter: filters,
            allAccounts: accountsRawData
        });
    };

    const { selectedAccountsAndSubs, onRemoveAccountsAndSubs } =
        useSelectedAccountsAndSubs({
            data: accountsAndSubs,
            rawData: accountsRawData,
            onApply: applyAccountsAndSubAccounts
        });

    const selectedFilters =
        Boolean(selectedOrderTypes?.length) ||
        hasSelectedDatesAndRange ||
        hasSelectedAccounts ||
        hasSelectedOriginLocations ||
        hasSelectedDestinationLocations ||
        (dangerousGoodsSelectedState &&
            dangerousGoodsSelectedState !== 'false') ||
        (watchListSelectedState && watchListSelectedState !== 'false') ||
        hasSelectedDeliveryTypes ||
        hasSelectedPartConditionTypes;

    const onStatusSelect = (status: PROGRESS_STATUSES) => {
        setParams({ status, page: 1 });

        getData({
            ...ordersConfig,
            filters: getFilters({
                date: getDateFilterData({
                    dateFrom: dateFilterData.from,
                    dateTo: dateFilterData.to,
                    dateType: dateFilterData.dateType
                }).filterData,
                showWatchList: watchListSelectedState,
                orderTypes: selectedOrderTypes,
                partConditionTypes: selectedPartConditionTypes,
                deliveryTypes: selectedDeliveryTypes,
                accountsAndSubs,
                originLocations,
                destinationLocations,
                accounts: accountsRawData,
                warehouses: warehousesData?.data,
                status
            }),
            page: 1
        });
    };

    return {
        searchQuery: search,
        searchInputValue: searchValue,
        onChangeSearch,
        onSubmitSearch,
        onSearchInputClean,
        lastUpdated: fromLastRefresh,
        onRefreshClick,
        applyOrderTypes,
        onRemoveOrderType,
        selectedOrderTypes,
        selectedPartConditionTypes,
        selectedDeliveryTypes,
        showDangerousGoods: dangerousGoodsSelectedState,
        showWatchList: watchListSelectedState,
        onRemoveDangerousGoodsParam,
        onRemoveWatchListParam,
        onRemovePartConditionParam,
        onRemoveDeliveryParam,
        onRemoveOriginLocationsParam,
        onRemoveDestinationLocationsParam,
        orderTypes,
        partConditionTypesList,
        deliveryTypesList,
        selectedFilters,
        clearAllSelectedFilters,
        dateFilterData,
        minAvailableDateRange,
        onChangeDates,
        onResetPeriod,
        selectedDates,
        selectedDatesLabel,
        accounts: accountsRawData,
        accountsAndSubs,
        originLocations,
        selectedOriginLocations,
        destinationLocations,
        selectedDestinationLocations,
        hasSelectedAccounts,
        hasSelectedOriginLocations,
        hasSelectedDestinationLocations,
        selectedAccountsAndSubs,
        onRemoveAccountsAndSubs,
        applyAccountsAndSubAccounts,
        onStatusSelect,
        status,
        warehousesData: warehousesData,
        isDestinationsLoading,
        ...pagination
    };
};
