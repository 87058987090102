import { useState, useEffect } from 'react';

import { useLocation, Location } from 'react-router-dom';

import { Account } from 'APIServices/users/UsersApi.types';
import { headerSelector } from 'components/UI/organisms/header/selector/headerSelector';
import { useAppDispatch, useAppSelector } from 'store';
import {
    resetOrderState,
    setIsBrowserBackButtonBlocked
} from 'store/slices/activeOrder/activeOrder';
import {
    setSelectedAccountAndSubAccount,
    resetSelectedAccountAndSubAccount,
    setIsChangedAccount
} from 'store/slices/adminPanel/AdminPanel';
import { resetBulkState } from 'store/slices/bulk/bulk';
import { UserTokenInfo } from 'store/slices/user/user';
import { isMobile, isMobileAndTablet } from 'utils/constants/breakpoints';
import { RoutesList } from 'utils/constants/RoutesList';
import { steps, TransferBySteps } from 'utils/constants/steps';
import { ModalData, useModal } from 'utils/hooks/useModal';
import { useUser } from 'utils/hooks/useUser';
import { useWindowDimentions } from 'utils/hooks/useWindowDimentions';
import { VoidFunction } from 'utils/types/general/general.types';
import { ACTIVE_ORDER_TYPE } from 'utils/types/order/activeOrder';

interface HeaderType {
    isChangeSubAccountOpen: boolean;
    accounts?: Account[];
    selectedRadio: string;
    setSelectedRadio?: (value: string) => void;
    onChangeAccountSubmit: VoidFunction;
    onAccountSelect: (value: string) => void;
    toggleChangeAccount: VoidFunction;
    handleInitialValues: (visible: boolean) => void;
    accountNumber?: string;
    subAccountNumber?: string;
    isSelectAccountVisible: boolean;
    isPopupVisible: boolean;
    userInfo?: UserTokenInfo | null;
    onToggleBurgerMenu: VoidFunction;
    description?: string;
    isAccountVisible: boolean;
    isMobile: boolean;
    disableAccountSelect: boolean;
}

interface IProps {
    toggleSiderOnMobile?: (isOpen: boolean) => void;
    isSiderOpenOnMobile?: boolean;
}

interface LocationState extends Location {
    state: {
        notAllowedOnMobilePage: boolean;
    };
}

const { ORDERS, MATERIALS, TRANSFERS, MANAGE_INVENTORY, RETURN_PARTS } =
    RoutesList;

const routesWithAccountSelector = [
    ORDERS.ROOT,
    MATERIALS.ROOT,
    TRANSFERS.ROOT,
    MANAGE_INVENTORY.LOCATE_CLOSEST_WAREHOUSE.ROOT,
    RETURN_PARTS.ROOT
];

const restrictedPagesOnMobile = [
    ORDERS.BULK.CREATE,
    MATERIALS.BULK.CREATE,
    TRANSFERS.BULK.CREATE,
    TRANSFERS.BULK_DEFECTIVE.CREATE,
    RETURN_PARTS.DEFECTIVE_FROM_CHOICE_UPLOAD.CREATE,
    RETURN_PARTS.DEFECTIVE_TO_CHOICE_UPLOAD.CREATE
];

const LastDefaultStep = steps.length;
const LastTransferWarehouseStep = TransferBySteps.length;

export const useHeader = ({
    toggleSiderOnMobile,
    isSiderOpenOnMobile
}: IProps = {}): HeaderType => {
    const location = useLocation() as LocationState;

    const dispatch = useAppDispatch();
    const {
        accountNumber,
        subAccountNumber,
        userInfo,
        description,
        accounts,
        selectedAccountIds,
        fetchAccounts
    } = useUser();
    const { width } = useWindowDimentions();
    const isMobileVersion = isMobileAndTablet(width);
    const isChoiceAdmin = userInfo?.isChoiceAdmin;

    const checkAvailableAccountSelectOnMobile = (routesList: string[]) => {
        return Boolean(
            routesList.find((route) => location.pathname.includes(route))
        );
    };

    const isSelectAccountVisible = checkAvailableAccountSelectOnMobile(
        routesWithAccountSelector
    );

    const isAccountRestrictedPageOnMobile =
        checkAvailableAccountSelectOnMobile(restrictedPagesOnMobile) &&
        isMobileVersion;

    useEffect(() => {
        if (isSelectAccountVisible) {
            fetchAccounts?.(true);
        }
    }, [isSelectAccountVisible]);

    const {
        isOpen: isChangeSubAccountOpen,
        toggle: toggleChangeAccount,
        setModalData: setAccountModalData,
        modalData
    } = useModal();

    const toggleBlockBackButton = () => {
        toggleChangeAccount();
        dispatch(setIsBrowserBackButtonBlocked(!isChangeSubAccountOpen));
    };

    const { activeSteps, isRedirectBlocked, loadings, orderType } =
        useAppSelector(headerSelector);

    const accountsIds = selectedAccountIds ?? '';

    const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
    const isAccountsLengthEqualOne = accounts?.length === 1;
    const isSubAccountsLengthEqualOne =
        accounts && accounts[0]?.subAccounts?.length === 1;

    const isSingleAccAndSubAcc =
        isAccountsLengthEqualOne && isSubAccountsLengthEqualOne;

    useEffect(() => {
        if (accounts) {
            let shouldSetDefault = !isChoiceAdmin;

            if (isChoiceAdmin && isSingleAccAndSubAcc) {
                shouldSetDefault = true;
            }

            if (shouldSetDefault) {
                const [account] = accounts;
                const [subAccount] = account.subAccounts || [];

                const payloadValues = {
                    accountNumber: account.accountNumber,
                    subAccountNumber: subAccount.subAccountNumber,
                    description: subAccount.description,
                    isExpandedRef: subAccount.isExpandedRef,
                    isMandatoryRef2: subAccount.isMandatoryRef2,
                    itemRef: subAccount.itemRef,
                    irpItemRef: subAccount.irpItemRef,
                    selectedAccountIds: `${account.accountId}-${subAccount.subAccountId}`
                };

                dispatch(setSelectedAccountAndSubAccount(payloadValues));
            }
        }
    }, [isChoiceAdmin, accounts, isSingleAccAndSubAcc]);

    useEffect(() => {
        if (isChoiceAdmin && !isSingleAccAndSubAcc) {
            dispatch(resetSelectedAccountAndSubAccount());
        }
    }, [location.pathname, isChoiceAdmin, isSingleAccAndSubAcc]);

    const splitRadioValue = (value: ModalData) => {
        const splitSelectedValue =
            typeof value === 'string' ? value?.split('-').map(Number) : '';

        const accId = splitSelectedValue[0];
        const subAccId = splitSelectedValue[1];

        const account = accounts?.find((item) => item.accountId === accId);
        const subAccount = account?.subAccounts.find(
            (item) => item.subAccountId === subAccId
        );
        return {
            accountNumber: account?.accountNumber || '',
            subAccountNumber: subAccount?.subAccountNumber || '',
            isExpandedRef: subAccount?.isExpandedRef,
            description: subAccount?.description || '',
            isMandatoryRef2: subAccount?.isMandatoryRef2,
            itemRef: subAccount?.itemRef,
            irpItemRef: subAccount?.irpItemRef,
            selectedAccountIds: value ?? ''
        };
    };

    const onAccountSelect = (value: string) => {
        setPopupVisible(false);

        if (isRedirectBlocked || value === selectedAccountIds) {
            setAccountModalData(value);
            toggleBlockBackButton();
            return;
        }

        const byActiveOrderCondition =
            activeSteps.activeOrder === LastDefaultStep;

        const byTransferWarehouseCondition =
            activeSteps.activeOrder === LastTransferWarehouseStep &&
            orderType === ACTIVE_ORDER_TYPE.TRANSFER_BY_WAREHOUSE;

        if (byActiveOrderCondition || byTransferWarehouseCondition) {
            dispatch(resetOrderState());
        }
        dispatch(setIsChangedAccount(true));
        dispatch(setSelectedAccountAndSubAccount(splitRadioValue(value)));
    };

    const onChangeAccountSubmit = () => {
        dispatch(resetOrderState());
        dispatch(resetBulkState());
        dispatch(setIsChangedAccount(true));
        toggleBlockBackButton();

        dispatch(setSelectedAccountAndSubAccount(splitRadioValue(modalData)));
    };

    const selectedAccount = splitRadioValue(accountsIds).accountNumber;
    const selectedSubAccount = splitRadioValue(accountsIds).description;

    const handleInitialValues = (visible: boolean) => {
        setPopupVisible(visible);
        if (visible && !selectedAccountIds && accounts) {
            const acc = accounts.find(
                (item) => item.accountNumber === accountNumber
            );
            const subAcc = acc?.subAccounts?.find(
                (item) => item.subAccountNumber === subAccountNumber
            );

            if (acc && subAcc) {
                dispatch(
                    setSelectedAccountAndSubAccount(
                        splitRadioValue(
                            `${acc.accountId}-${subAcc.subAccountId}`
                        )
                    )
                );
            }
        }
    };

    const onToggleBurgerMenu = () => {
        toggleSiderOnMobile?.(!isSiderOpenOnMobile);
    };

    const isAccountVisible = Boolean(
        isSelectAccountVisible &&
            accounts &&
            !isSingleAccAndSubAcc &&
            !isAccountRestrictedPageOnMobile
    );

    return {
        accounts,
        isChangeSubAccountOpen,
        toggleChangeAccount: toggleBlockBackButton,
        selectedRadio: accountsIds,
        onAccountSelect,
        onChangeAccountSubmit,
        subAccountNumber: selectedSubAccount || subAccountNumber,
        accountNumber: selectedAccount || accountNumber,
        handleInitialValues,
        isPopupVisible,
        isSelectAccountVisible,
        userInfo,
        onToggleBurgerMenu,
        description,
        isAccountVisible,
        isMobile: isMobile(width),
        disableAccountSelect: loadings.activeOrder
    };
};
