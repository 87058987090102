import {
    BULK_INBOUND_STEPS,
    BULK_OUTBOUND_STEPS,
    BULK_TRANSFERS_STEPS
} from 'utils/types/bulkOrder/bulk.types';
import { TRANSFER_WAREHOUSE_STEPS } from 'utils/types/materials/transferByWarehouse';
import { ORDER_BY_PART_STEPS } from 'utils/types/order/outboundOrder.types';

export const steps = Object.values(ORDER_BY_PART_STEPS);
export const bulkInboundSteps = Object.values(BULK_INBOUND_STEPS);
export const bulkOutboundSteps = Object.values(BULK_OUTBOUND_STEPS);
export const bulkTransfersSteps = Object.values(BULK_TRANSFERS_STEPS);
export const TransferBySteps = Object.values(TRANSFER_WAREHOUSE_STEPS);

export const defaultVisibleQuantity = 3;

export const pickSerialsTooltipText =
    'You can let the system select the serial numbers by simply skipping this action.';

export const itemReferenceNumbersDefaultValue = {
    referenceNumber: '',
    referenceQuantity: ''
};

// Cache lifetime on PreCheck - 15 minutes
export const CACHE_EXP_TIMER = 900000;
