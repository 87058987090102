import React, { useEffect, useRef, useState } from 'react';

import { Loader } from 'components/UI/atoms/loader/Loader';
import { PanelBlock } from 'components/UI/organisms/adminPanel/components/panelBlock/PanelBlock';
import { UnexpectedError } from 'components/UI/organisms/unexpectedError/UnexpectedError';
import { DASHBOARDS, useDundas } from 'dundas/useDundas';
import { PERMISSIONS_KEYS } from 'utils/constants/permissionsKeys';

import styles from './DundasReport.module.less';

type AvailablePermissionKeys =
    | PERMISSIONS_KEYS.invoices
    | PERMISSIONS_KEYS.storageBilling
    | PERMISSIONS_KEYS.clientSpend
    | PERMISSIONS_KEYS.dutiesTaxesAnalysis
    | PERMISSIONS_KEYS.analyticsOrderHistory
    | PERMISSIONS_KEYS.cycleCountPerformance
    | PERMISSIONS_KEYS.globalCapabilities
    | PERMISSIONS_KEYS.partMaster
    | PERMISSIONS_KEYS.substituteParts
    | PERMISSIONS_KEYS.missingPart
    | PERMISSIONS_KEYS.clientBilling
    | PERMISSIONS_KEYS.inventoryTransactionHistory
    | PERMISSIONS_KEYS.outstandingReturns
    | PERMISSIONS_KEYS.outstandingInbounds
    | PERMISSIONS_KEYS.inventoryOnHand
    | PERMISSIONS_KEYS.materialInReviewAnalysis
    | PERMISSIONS_KEYS.dockToStock
    | PERMISSIONS_KEYS.outboundDriveFillRate
    | PERMISSIONS_KEYS.outboundDrivePerformance
    | PERMISSIONS_KEYS.tenderToCarrierPerformance;

export const DundasReport = ({
    permissionKey
}: {
    permissionKey: AvailablePermissionKeys;
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const { load, isDundasReady, getDashboardByPage, isDundasError } =
        useDundas(permissionKey);

    const firstReportRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isDundasReady && firstReportRef) {
            const dashboards = getDashboardByPage();

            load({
                targetBlock: firstReportRef,
                fileSystemId: dashboards?.[DASHBOARDS[permissionKey]]
            }).finally(() => setIsLoading(false));
        }
    }, [isDundasReady]);

    return (
        <PanelBlock contentClassName={styles.Reports}>
            <div className={styles.Report}>
                {isDundasError ? (
                    <UnexpectedError />
                ) : (
                    <>
                        {(!isDundasReady || isLoading) && (
                            <Loader className={styles.Loader} />
                        )}
                        <div className={styles.Chart} ref={firstReportRef} />
                    </>
                )}
            </div>
        </PanelBlock>
    );
};
