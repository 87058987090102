import { IAppConfig, IMainConfig } from 'config/AppConfig/types';
import { B2CController } from 'config/B2C/B2CController';
import { FeatureFlagsController } from 'config/FeatureFlags/FeatureFlagsController';

export class AppController {
    protected static instance: AppController;

    public config: IMainConfig | null = null;
    private b2cInstance: B2CController = B2CController.getInstance();
    private featureFlagsController: FeatureFlagsController =
        FeatureFlagsController.getInstance();

    public static getInstance(): AppController {
        if (!AppController.instance) {
            AppController.instance = new AppController();
        }

        return AppController.instance;
    }

    public setConfig(config: IAppConfig): void {
        if (config) {
            if (config.appConfig) {
                this.b2cInstance.setB2CData(config.appConfig.b2c);
            }

            if (config.featureFlags) {
                this.featureFlagsController.config = config.featureFlags;
            }
        }
        this.config = config.appConfig;
    }
}
