import {
    AvailableServices,
    Service
} from 'utils/types/deliveryServices/deliveryServices.types';

export enum DELIVERY_DATA {
    immediateCourierServices = 'Courier',
    availableServices = 'Available Services',
    nextFlightServices = 'Next Flight Out',
    scheduledCourierServices = 'Courier',
    customerServices = 'Carrier - Arranged by Recipient',
    carrierServices = 'Carrier'
}

export enum SERVICES {
    ImmediateCourierServices = 'immediateCourierServices',
    AvailableServices = 'availableServices',
    NextFlightServices = 'nextFlightServices',
    ScheduledCourierServices = 'scheduledCourierServices',
    CustomerServices = 'customerServices',
    CarrierServices = 'carrierServices'
}

export enum DELIVERY_TRANSPORT_TYPES {
    Immediate = 'Immediate',
    Carrier = 'Scheduled',
    WillCall = 'WillCall'
}

export enum DELIVERY_UI_NAMES {
    Immediate = 'Immediate Delivery',
    Scheduled = 'Scheduled Delivery',
    WillCall = 'Customer/Technician Pick Up'
}

export type Services = {
    [key in keyof typeof DELIVERY_DATA]: Service[];
};

export type ServicesKeys = keyof typeof DELIVERY_DATA;

export interface Delivery {
    key: DELIVERY_TRANSPORT_TYPES;
    data: AvailableServices;
}

export enum DELIVERY_FORM_FIELDS {
    CITY = 'city',
    STATE = 'state',
    COUNTRY = 'country',
    POSTAL_CODE = 'postalCode'
}
