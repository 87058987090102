import React from 'react';

import { useGetClosestWarehousesMutation } from 'APIServices/manageInventory/GetClosestWarehouses.api';
import { EmptyState } from 'components/UI/atoms/emptyState/EmptyState';
import { PanelBlock } from 'components/UI/organisms/adminPanel/components/panelBlock/PanelBlock';
import { LocateClosestWarehouse } from 'components/UI/organisms/manageInventory/locateClosestWarehouse/LocateClosestWarehouse';
import { STATUS_CODES } from 'utils/types/api/api.types';

import styles from './LocateClosestWarehouse.module.less';

export const LocateClosestWarehouseTemplate = () => {
    const [getData, { data, isLoading, reset }] =
        useGetClosestWarehousesMutation();

    const statusCode = data?.statusCode;

    const showErrorScreen =
        statusCode &&
        statusCode !== STATUS_CODES.INVALID_DESTINATION &&
        statusCode !== STATUS_CODES.FILTERED_DATA_NOT_FOUND;

    return (
        <>
            {showErrorScreen ? (
                <EmptyState
                    title="Unexpected error occurred"
                    className={styles.Error__Block}
                />
            ) : (
                <PanelBlock
                    title="Locate Closest Warehouse"
                    hideHeaderOnMobile={false}
                    withDivider={true}
                    fullWidth={false}
                    fullHeight={false}
                    isFullWidthOnSmallDesktop={true}
                >
                    <LocateClosestWarehouse
                        getData={getData}
                        isLoading={isLoading}
                        closestWarehouseData={data}
                        reset={reset}
                    />
                </PanelBlock>
            )}
        </>
    );
};
