import React, { useEffect, useState } from 'react';

import { useGetOrdersMutation } from 'APIServices/trackOrders/TrackOrders.api';
import {
    GetOrdersRequest,
    GetOrdersDTO
} from 'APIServices/trackOrders/TrackOrdersApi.types';
import { PanelBlock } from 'components/UI/organisms/adminPanel/components/panelBlock/PanelBlock';
import { OrdersList } from 'components/UI/organisms/trackOrders/orderList/OrdersList';
import styles from 'components/UI/organisms/trackOrders/orderList/OrdersList.module.less';
import { OrdersListWrapper } from 'utils/HOCs/OrdersListWrapper';
import { useWindowDimentions } from 'utils/hooks/useWindowDimentions';

export const OrdersListTemplate = () => {
    const searchPlaceholder =
        'Search by full Pro Number, Air Waybill or Reference Number';

    const [getData, options] = useGetOrdersMutation();
    const { width } = useWindowDimentions();
    const [getOrdersRequest, setGetOrdersRequest] =
        useState<GetOrdersRequest>();

    useEffect(() => {
        return () => {
            getOrdersRequest?.abort();
        };
    }, [getOrdersRequest]);

    const getDataResponse = (config: GetOrdersDTO) => {
        getOrdersRequest?.abort();
        const getDataRequest = getData(config);
        setGetOrdersRequest(getDataRequest);
    };

    const statusTotals = options.data?.data.statusTotals;
    const isLoading = Boolean(options?.isUninitialized || options?.isLoading);

    return (
        <PanelBlock title="Track Orders" contentClassName={styles.ScreenBlock}>
            <OrdersListWrapper
                placeholder={searchPlaceholder}
                styles={styles}
                getData={getDataResponse}
                statuses={statusTotals}
                isLoading={isLoading}
                width={width}
                orderCacheId={options.requestId}
            >
                <OrdersList
                    getOrdersList={getDataResponse}
                    isLoading={isLoading}
                    options={options}
                    width={width}
                />
            </OrdersListWrapper>
        </PanelBlock>
    );
};
