import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppController } from 'config/AppConfig/AppConfig';
import { APP_ERRORS, IFeatureFlags } from 'config/AppConfig/types';
import { AppRoutes } from 'routes/Routes';
import store from 'store';
import {
    getLocalStorageItem,
    LOCAL_STORAGE_FIELDS
} from 'utils/helpers/localStorage';
import { Boundary } from 'utils/HOCs/ErrorBoundary';
import { CONFIG_FILES } from 'utils/types/general/configs.types';

import reportWebVitals from './reportWebVitals';

import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-600.css';
import '@fontsource/inter/latin-700.css';

import 'antd/dist/antd.min.css';
import 'simplebar-react/dist/simplebar.min.css';

import './index.less';

const container =
    document.getElementById('root') || document.createElement('div');
const root = createRoot(container);

const getFeatureFlags = async (): Promise<IFeatureFlags | undefined> => {
    try {
        const featureFlagsConfig = await fetch(CONFIG_FILES.FEATURE_FLAGS, {
            cache: 'no-store'
        });
        const featureFlagsData: IFeatureFlags = await featureFlagsConfig.json();

        if (featureFlagsData && featureFlagsData.flags) return featureFlagsData;
    } catch (e) {
        console.log('FeatureFlags error: ', e);
        return;
    }
};

const loadApp = async () => {
    try {
        const data = await fetch(CONFIG_FILES.APP_CONFIG, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const config = await data.json();
        const featureFlagsConfig = await getFeatureFlags();
        const darkModeValue = getLocalStorageItem(
            LOCAL_STORAGE_FIELDS.DARK_MODE
        );
        document.documentElement.setAttribute(
            'data-theme',
            darkModeValue === 'true' ? 'dark' : 'light'
        );
        if (config) {
            AppController.getInstance().setConfig({
                appConfig: config,
                featureFlags: featureFlagsConfig
            });

            const mainContent = <AppRoutes />;

            root.render(
                <Provider store={store}>
                    <BrowserRouter>
                        <Boundary>{mainContent}</Boundary>
                    </BrowserRouter>
                </Provider>
            );
        } else {
            console.error(APP_ERRORS.BROKEN_FILE);
        }
    } catch (e) {
        console.error(APP_ERRORS.NO_FILE);
    }
};

loadApp();
reportWebVitals();
